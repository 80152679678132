<template>
    <div>


        <!-- 
        <div class="row text-center">
            <p class="col-12">O nosso contador está com : <strong>{{contador}}</strong> </p>

            <hr class="col-12">

            <div class="col-6 text-center" @click="decrementa"> <button> - </button></div>
            <div class="col-6 text-center" @click="incrementa"> <button> + </button></div>

        </div>
         -->


        <form class="row col-12" @submit.prevent="guardar">

            <div class="formgroup col-12 row">
                <label class="col-12 col-md-4" for="frm_tipo">Tipo</label>
                <div class="col-12 col-md-8 row">
                    <label class="col-6" for="frm_tipo_1">
                        <input id="frm_tipo_1" type="radio" v-model="at.tipo" value="Particular"> Particular
                    </label>   
                    <label class="col-6" for="frm_tipo_2">
                        <input id="frm_tipo_2" type="radio" v-model="at.tipo" value="Corporate"> Corporate
                    </label>   
                </div>
            </div>

            <div class="formgroup col-12 row" v-if="at.tipo === 'Corporate' ">
                <label class="col-12 col-md-4" for="frm_account">Empresa</label>
                <!-- <input class="col-12 col-md-8" type="text" id="frm_account" v-model="at.account_id" required> -->
                <select class="col-12 col-md-8"  v-model="at.account_id" id="frm_account" required>
                    <option value="">Seleccionar Account</option>
                </select>
            </div>



            <div class="formgroup col-12 row">
                <label class="col-12 col-md-4" for="frm_destino">Destino</label>
                <input class="col-12 col-md-8" type="text" id="frm_destino" v-model="at.destino">
            </div>

            <div class="formgroup col-12 row">
                <label class="col-12 col-md-4" for="frm_datas">Datas</label>
                
                <date-range-picker class="col-12 col-md-8 m-0 p-0" v-model="at.rq.datas"  :ranges="false" :always-show-calendars="true" :singleDatePicker="false" :locale="config_calendar.locale" :timePicker="false" :minDate="moment().add(-1, 'day').format()" :maxDate="moment().add(1, 'year').format()">
                    <template #input="picker" style="min-width: 350px;">
                        <small>De</small> <strong>{{ picker.startDate | date }}</strong> <small>a</small> <strong>{{ picker.endDate | date }}</strong>
                    </template>
                </date-range-picker>
                
            </div>


            <div class="formgroup col-12 row">
                <label class="col-12 col-md-4" for="frm_tipo">Nº Pax</label>
                <div class="col-12 col-md-8 row m-0 p-0">
                    <label class="col row" for="frm_npax_adulto">
                        <label class="col-6" for="">Adultos</label>
                        <input class="col-6" id="frm_tipo_1" type="number" v-model="at.npax.adult" value="Particular"> 
                    </label>   
                    <label class="col row" for="frm_npax_crianca">
                        <label class="col-6" for="">Crianças</label>
                        <input class="col-6" id="frm_tipo_2" type="number" v-model="at.npax.children" value="Corporate"> 
                    </label>   
                    <label class="col row" for="frm_npax_bebe">
                        <label class="col-6" for="">Bebés</label>
                        <input class="col-6" id="frm_tipo_2" type="number" v-model="at.npax.infant" value="Corporate"> 
                    </label>   
                </div>
            </div>


            <div class="formgroup col-12 row">
                <div class="col-12 my-4 px-5 d-flex justify-content-center">
                    
                    <div class="col-12 col-md-4 text-center m-4 p-3" style="background-color: #ededed;">
                        <label class="col-12">Incluir Alojamento <input class="ml-4" type="checkbox" v-model="at.rq.alojamento"> </label>
                    </div>
                    
                    <div class="col-12 col-md-4 text-center m-4 p-3" style="background-color: #ededed;">
                        <label class="col-12">Incluir Voo(s) <input class="ml-4" type="checkbox" v-model="at.rq.voos"> </label>
                    </div>

                    <div class="col-12 col-md-4 text-center m-4 p-3" style="background-color: #ededed;">
                        <label class="col-12">Incluir Rent a Car <input class="ml-4" type="checkbox" v-model="at.rq.rent_a_car"> </label>
                    </div>


                    
                </div>
                <div class="row col-12 mt-0 pt-0 text-left" v-if="at.rq.alojamento && at.rq.voos">
                    <i class="fa fa-info mr-5"></i> Este pedido requer, por lei, subscrição de seguro de viagem.
                </div>


                <!-- ALOJAMENTO(s) -->

                <div class="col-12 m-0 my-5 p-0 alojamento panel" v-if="at.rq.alojamento">
                        <div class="panel-header px-2 py-3 mb-5">
                            Prefêrencias de Alojamento
                        </div>

                        <div class="formgroup col-12 row">
                            <label class="col-12 col-md-4" for="frm_tipohotel">Tipo Alojamento</label>
                            <!-- <input class="col-12 col-md-8" type="text" id="frm_tipohotel" v-model="at.prefs.alojamento.tipo_hotel"> -->
                            <select class="col-12 col-md-8" v-model="at.prefs.alojamento.tipo_hotel" id="">
                                <option>-- Seleccionar tipo de Alojamento --</option>
                                <option disabled>Hotel</option>
                                    <option value="Hotel 5 Estrelas">5 Estrelas</option>
                                    <option value="Hotel 4 Estrelas">4 Estrelas</option>
                                    <option value="Hotel 3 Estrelas">3 Estrelas</option>
                                    <option value="Hotel 2 Estrelas">2 Estrelas</option>
                                    <option value="Hotel 1 Estrela">1 Estrela</option>
                                <option disabled>Outros</option>
                                    <option value="Alojamento Local">Aparthotel</option>
                                    <option value="Alojamento Local">Alojamento Local</option>
                                    <option value="Hostels / Albergues / Pousadas da Juventude">Hostels / Albergues / Pousadas da Juventude</option>

                                    
                            </select>
                        </div>

                        <div class="formgroup col-12 row">
                            <label class="col-12 col-md-4" for="frm_regimealojamento">Regime Alojamento</label>
                            <!-- <input class="col-12 col-md-8" type="text" id="frm_regimealojamento" v-model="at.prefs.alojamento.regime_alojamento"> -->
                            <select id="frm_regimealojamento" class="col-12 col-md-8" v-model="at.prefs.alojamento.regime_alojamento">
                                <option>-- Seleccionar Regime Preferencial --</option>
                                <option value="SA">Só Alojamento</option>
                                <option value="APA">Alojamento e Pequeno Almoço</option>
                                <option value="MP">Meia Pensão</option>
                                <option value="PC">Pensão Completa</option>
                                <option value="TI">Tudo Incluído</option>
                            </select>
                        </div>
                </div>


                <!-- VOO(s) -->
                
                <div class="col-12 m-0 my-5 p-0 voo panel" v-if="at.rq.voos">
                    <div class="panel-header px-2 py-3 mb-5">
                    Preferências nos Voos
                    </div>

                    <div class="formgroup col-12 row">
                        <label class="col-12 col-md-4" for="frm_tipohotel">Classe preferencial Voo</label>
                        <!-- <input class="col-12 col-md-8" type="text" id="frm_tipohotel" v-model="at.prefs.voos.classe_voo"> -->
                        <select class="col-12 col-md-8" v-model="at.prefs.voos.classe_voo" id="">
                            <option value="">Seleccione uma Clase de Voo</option>
                            <option value="First">First</option>
                            <option value="Executiva">Executiva</option>
                            <option value="Económica">Económica</option>
                            <option disabled>Combinadas</option>
                            <option value="Ida Executiva / Regresso Económica">Ida Executiva / Regresso Económica</option>
                            <option value="Ida Económica / Regresso Executiva">Ida Económica / Regresso Executiva</option>
                        </select>
                    </div>

                    <div class="formgroup col-12 row">
                        <label class="col-12 col-md-4" for="frm_voo_lugarpreferencial">Lugar preferencial no Voo</label>
                        <!-- <input class="col-12 col-md-8" type="text" id="frm_voo_lugarpreferencial" v-model="at.prefs.voos.lugar_preferencial"> -->
                        <select class="col-12 col-md-8" v-model="at.prefs.voos.lugar_preferencial" id="frm_voo_lugarpreferencial">
                            <option disabled>Frente</option>
                                <option value="">Janela</option>
                                <option value="">Meio</option>
                                <option value="">Coxia</option>
                            <option disabled>Centro</option>
                                <option value="">Janela</option>
                                <option value="">Meio</option>
                                <option value="">Coxia</option>
                            <option disabled>Fundo</option>
                                <option value="">Janela</option>
                                <option value="">Meio</option>
                                <option value="">Coxia</option>
                        </select>
                    </div>

                    <div class="formgroup col-12 row">
                        <label class="col-12 col-md-4" for="frm_voo_horapartida">Horário preferencial Voo Ida</label>
                        <!-- <input class="col-12 col-md-8" type="text" id="frm_voo_horapartida" v-model="at.prefs.voos.horario_voo_ida"> -->
                        <select v-model="at.prefs.voos.horario_voo_ida" id="frm_voo_horapartida" class="col-12 col-md-8">
                            <option value="">Sem constrangimentos de tempo</option>
                            <option value="Tão cedo quanto possível">Tão cedo quanto possível</option>
                            <option value="Durante a Manhã" selected>Durante a manhã</option>
                            <option value="Durante a Tarde" >Durante a tarde</option>
                            <option value="Final de dia">Final de dia</option>
                            <option value="Tão tarde quanto possível">Tão tarde quanto possível</option>  
                        </select>
                    </div>

                    <div class="formgroup col-12 row">
                        <label class="col-12 col-md-4" for="frm_voo_horaregresso">Horário preferencial Voo Regresso</label>
                        <!-- <input class="col-12 col-md-8" type="text" id="frm_voo_horaregresso" v-model="at.prefs.voos.horario_voo_regresso"> -->
                        <select v-model="at.prefs.voos.regresso" id="frm_voo_horaregresso" class="col-12 col-md-8">
                            <option value="">Sem constrangimentos de tempo</option>
                            <option value="Tão cedo quanto possível">Tão cedo quanto possível</option>
                            <option value="Durante a Manhã" >Durante a manhã</option>
                            <option value="Durante a Tarde" >Durante a tarde</option>
                            <option value="Final de dia">Final de dia</option>
                            <option value="Tão tarde quanto possível" selected>Tão tarde quanto possível</option>  
                        </select>
                    </div>

                    <div class="formgroup col-12 row">
                        <label class="col-12 col-md-4" for="frm_voo_obs">Obs</label>
                        <input class="col-12 col-md-8" type="text" id="frm_voo_obs" v-model="at.prefs.voos.obs_voo">
                    </div>
                </div>

                <!-- Rent a Car (s) -->

                <div class="col-12 m-0 my-5 p-0 alojamento panel" v-if="at.rq.rent_a_car">
                    <div class="panel-header px-2 py-3 mb-5">
                        Prefêrencias de Veículo
                    </div>
                        <div class="formgroup col-12 row">
                            <label class="col-12 col-md-4" for="frm_rentacar_tipoveiculo">Tipo Veículo</label>
                            <!-- <input class="col-12 col-md-8" type="text" id="frm_rentacar_tipoveiculo" v-model="at.prefs.rent_a_car.tipo_veiculo"> -->
                            <select v-model="at.prefs.rent_a_car.tipo_veiculo" id="frm_rentacar_tipoveiculo" class="col-12 col-md-8">
                                <option value="Ultra Compacto">Ultra Compacto</option>
                                <option value="Hatchback">Hatchback</option>
                                <option value="Sedan">Sedan</option>
                                <option value="Sedan Executivo">Sedan Executivo</option>
                                <option value="Sedan Luxo">Sedan Luxo</option>
                                <option value="Descapotável">Descapotável</option>
                                <option value="Crossover">Crossover</option>
                                <option value="SUV">SUV</option>
                                <option value="Van">Van</option>
                                <option value="Pickup / Todo o Terreno">Pickup / Todo o Terreno</option>
                            </select>
                        </div>

                        <div class="formgroup col-12 row">
                            <label class="col-12 col-md-4" for="frm_rentacar_obs">Observações</label>
                            <input class="col-12 col-md-8" type="text" id="frm_rentacar_obs" v-model="at.prefs.rent_a_car.obs" placeholder="P.Ex.: Veículo apenas para 2 dias">
                        </div>
                </div>
                
            </div>


            <div class="formgroup col-12 row panel">
                <div class="col-12 panel-header px-2 py-3 mb-5">
                    Observações
                </div>
                <label class="col-12 col-md-4" for="frm_obs"></label>
                <textarea class="col-12 col-md-8" rows="10" type="text" id="frm_obs" v-model="at.obs"></textarea>
            </div>

            
            <div class="col-12 row d-flex justify-content-center text-center">
                <button class="btn btn-primary col-12 py-3" type="submit">Guardar</button>
            </div>


                                <div class="col-12"> <pre>{{at}}</pre> </div>


        </form>

    </div>
</template>

<script>
import DateRangePicker from 'vue2-daterange-picker'
//you need to import the CSS manually (in case you want to override it)
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

export default {
name: 'PedidoViagem',
components: {
    DateRangePicker
},
data() {
    let config_calendar = {
        locale: {   direction: 'ltr',
                    format: 'dd-mm-YYYY',
                    separator: ' - ',
                    applyLabel: 'Aplicar',
                    cancelLabel: 'Cancelar',
                    weekLabel: 'Sem',
                    customRangeLabel: 'Intervalo Personalizado',
                    daysOfWeek: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
                    monthNames: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
                    firstDay: 0
                },
    }
    return {
        //declaração de variáveis
        config_calendar: config_calendar,
        at: {},
    };
},
created() {
    // this.runSave();
    this.$set(this.at, 'tipo', 'Corporate');
    this.$set(this.at, 'npax', {});
    this.$set(this.at, 'data_inicio', null);
    this.$set(this.at, 'data_fim', null);

    this.$set(this.at, 'rq', {datas: {}, alojamento: false, voos: false, rent_a_car: false });
    this.$set(this.at, 'prefs', {alojamento: {}, voos: {}, rent_a_car: {} });


},
watch: {
    'at.tipo': function(){
        if(this.at.tipo == 'Particular') this.$delete(this.at, 'account_id');
    },
    'at.rq.datas': function(){
        // console.log('datas alteradas');
        // console.log(this.at.rq.datas);

        this.$set(this.at, 'data_inicio', window.moment(this.at.rq.datas.startDate).format('DD-MM-YYYY'));
        this.$set(this.at, 'data_fim', window.moment(this.at.rq.datas.endDate).format('DD-MM-YYYY'));
    }
},
filters: {
    date(val) { return val ? window.moment(val).format('DD-MM-YYYY') : '' }
},
methods: {

    guardar(){
        // console.log(this.at);
        
    }

},
computed: {

}
};
</script>

<style scoped>
    .formgroup{ margin-bottom: 25px; }
    p{ color: #900; }
</style>