<template>
    <div>

        <div class="row mb-4" v-for="(ct, k) in contact_log" :key="k">
            <div class="col-12 row">
                <div class="col-3 text-center">
                    <i class="fa fa-2x fa-globe" v-if="ct.type == 'website'"></i>
                    <i class="fa fa-2x fa-mobile xfa-envelope" v-if="ct.type == 'sms'"></i>
                    <i class="fa fa-2x fa-at" v-if="ct.type == 'email'"></i>
                    <i class="fa fa-2x fa-phone"  v-if="ct.type == 'Telefone'"></i>
                </div>
                <div class="col-9 border-bottom">
                    <p><em>{{ct.date}} <small v-if="ct.type">via {{ct.type}}</small></em> </p>
                    <p>{{ct.contact}}</p>    
                    <p v-if="ct.created_by"><small>registado por {{ct.created_by}} </small></p>    
                </div>
                <!-- <div class="col-12">
                    <pre>{{ct}}</pre>
                </div> -->
            </div>
        </div>

        
    </div>
</template>

<script>

export default {
name: 'AtendimentoContactLog',
props: ['contact_log'],
components: {
},
data() {
    return {
        //declaração de variáveis locais
        
    };
},
created() {
    
},
mounted() {
    
},
watch: {
    
},
filters: {
    
},
methods: {

},
computed: {

}
};
</script>

<style scoped></style>