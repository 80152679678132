<template>
<div class="atendimento">
    <b-row>
        <b-col xs="12">
        <Widget style="background-color: #ededed;"
            title=""
            bodyClass="widget-table-overflow"
            customHeader
        >
        <!-- <router-link    to="/atendimento/novo"
                        v-slot="{}"
                        > -->
            <h5 class="mb-3" style="cursor: pointer;" @click="$router.push({ path: '/atendimento/novo', params: {} })">Registo <span class='fw-semi-bold'>Novo Atendimento</span> <i class='fa fa-plus fa-pull-right'></i> </h5>
        <!-- </router-link> -->


        </Widget>
        </b-col>
    </b-row>

    <b-row>
        <b-col xs="12" sm="12" md="8" lg="9">
            <Widget>

                <div class="col-12 row mt-3 align-items-center text-center ">
                    <div class="col-1">ID</div>
                    <div class="col-1">Status</div>
                    <div class="col-2">Data</div>
                    <div class="col-2">Cliente</div>
                    <div class="col-2">Atendimento</div>
                    <div class="col-2">Atribuído a</div>
                    <div class="col-2">Agentes Associados</div>
                </div>

                <div class="cs_item col-12 row mt-3 align-items-center text-center" v-for="(cs, k) in cs_list" :key="k" @click="$router.push({ path: '/atendimento/'+cs.id, params: {} })" style="cursor: pointer;">
                    
                    <div class="col-1">{{cs.id}}</div>
                    <div class="col-1">{{cs.status}} <span v-if="cs.status_id == 3">para {{cs.defered_until}}</span></div>
                    <div class="col-2">{{cs.creation_date}} <br>via ({{cs.channel}}) </div>
                    <div class="col-2">{{cs.name}}</div>
                    <div class="col-2">
                        {{cs.attendancy_type}} 
                        <div class="col-12 m-0 p-0">
                            <!-- {{cs.rq}} -->
                            <span v-if="cs.attendancy_type != 'Pedido de Viagem'">{{JSON.parse(cs.rq).subject}}</span>
                            <span v-if="cs.attendancy_type == 'Pedido de Viagem'">{{JSON.parse(cs.rq).destino}}</span>
                        </div>
                    </div>
                    <div class="col-2">{{cs.owner}}</div>
                    <div class="col-2">{{cs.assigned_to}}</div>
                    
                    
                    <!-- <pre class="col-12" v-if="cs.closed != 1">
                        {{json(cs)}}
                    </pre> -->
                </div>
                
            </Widget>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="3">
            <Widget>
                
            </Widget>
        </b-col>
    </b-row>
</div>
</template>

<script>  

export default {
name: 'Atendimento',
components: {
    
},
data() {
    return {
        teste: 'aaa',
        cs_list: [],
        
    };
},
created() {
    let self = this;
    let user = window.jsonxDec(window.localStorage.getItem('user'));
    this.api.get('atendimento/list/'+user.id).then(function(response){
                
                if(response.success){
                    self.cs_list = response.dados;
                    self.$forceUpdate();
                }else{
                    console.log(response.error);
                }
            });
},
methods: {
    clickNovoAtendimento(){
        // alert("click no titulo");
        this.$router.push({ path: '/atendimento/novo', params: {} });
    },
    json(data){
        data.rq = JSON.parse(data.rq);
        return data
    }

},
computed: {

}
};
</script>

<style lang="scss" scoped>
    .cs_item{padding: 5px 0px;}
    .cs_item div{font-size:12px;}
    .cs_item:hover{
        background: rgba(250,250,250,0.8);
        cursor: pointer;
    }
</style>
