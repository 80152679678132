<template>
    <div>
        
        <div class="row mb-4" v-for="(q, k) in quotes" :key="k">
            <div class="col-12 row">
                <div class="col-12">
                    <pre>{{q}}</pre>
                </div>
            </div>
        </div>

        
    </div>
</template>

<script>

export default {
name: 'AtendimentoQuotes',
props: ['quotes'],
components: {
},
data() {
    return {
        //declaração de variáveis locais
        
    };
},
created() {
    
},
mounted() {
    
},
watch: {
    
},
filters: {
    
},
methods: {

},
computed: {

}
};
</script>

<style scoped></style>