<template>
    <div class="auth-page">
        <!-- <b-container v-if="frm">

        </b-container> -->
        <b-container>
            <h5 class="auth-logo">
            <!-- <i class="fa fa-circle text-primary"></i> -->
            <img :src="appConfig.options.base_url+appConfig.options.brand_logo" alt="" style="height: 75px;"  v-if="appConfig.options && appConfig.options.brand_logo">
            <img src="../../assets/imagens/travelgate_logo-h.png" alt="" style="height: 75px;" v-else="">
            <!-- <i class="fa fa-circle text-danger"></i> -->
            </h5>
            <Widget class="widget mx-auto text-center" title="" customHeader>
    
                <form class="mt" @submit.prevent="recover_account" v-if="!user.id"> 
    
                <!-- DADOS CONTA -->          
                <fieldset class="col-12 row mt-5">
                    <div class="formgroup col-12 row">
                    <label class="col-4" for="frmEmail">Email</label>
                    <input id="frmEmail" class="col-8" v-model.lazy="user.email" type="email" placeholder="@" required/>
                    <div class="info col-12 d-flex justify-content-end">
                        <p class="col-8"></p>
                        <p class="col-8 error" v-if="frmerror.email"><em>Não encontrámos nenhum registo com este endereço. Por favor contacte o suporte.</em></p>
                    </div>
                    </div>

                    <div class="col-12 row justify-content-center">
                        <div class="col-12 text-center">
                            <b-button type="submit" size="sm" class="auth-btn my-5" variant="inverse">Recuperar acesso</b-button>
                        </div>

                        <div class="col-12 text-center">
                            <router-link class="d-block text-center mt-4" to="/login">Regressar ao login</router-link>
                        </div>
                    </div>
                
                </fieldset>  
                </form>
                

                <div v-else="">
                    Recebeu no seu email um link para efetuar reset à sua senha.
                </div>
                
                <p class="widget-auth-info">
                
                </p>
                
            </Widget>
        </b-container>
        <footer class="auth-footer">
            2020 &copy; {{ (appConfig.options) ? appConfig.options.support_team : ''}}
        </footer>
    </div>
</template>
<script>
export default {
    name: 'RecuperarSenha',
    components: {},
    data() {
        return {
            
            frmerror:{},

            user: {},
            hash: {},
            confirm_fields: {},
        }
    },
    computed: {
        hash_id (){
            return null
        },
        hash_confirmation (){
            return null
        }
    },
    watch: {
        'user.email': function (){
            this.validateConfirmFields();
        },
        'user.pwd': function (){
            this.validateConfirmFields();
        },
        confirm_fields: {
            deep: true,
            handler() {this.validateConfirmFields()}
        }
    },
    methods: {
        validateConfirmFields(){
            let self = this;
            // console.log('entrei na validação');
            Object.keys(this.confirm_fields).forEach(k => {
                let v = self.confirm_fields[k];

                if(v.length > 0){
                    if(v != self.profile[k]){
                        // console.log(v+' != '+self.profile[k]);
                        self.frmerror[k]= true;
                    }else{
                        // console.log(v+' == '+self.profile[k]);
                        if(self.frmerror[k]) { self.$delete(self.frmerror, k);}
                    }
                }else{
                    // console.log(v+' == '+self.profile[k]);
                    self.frmerror[k]= false;
                    if(self.frmerror[k]) { self.$delete(self.frmerror, k); }
                }
            })

        },


        recover_account(){
            let self = this;

            this.api.post('users/auth/recover_account', this.user).then( (r) => {
                console.log(r);
                if(r.success){
                    self.frmLog(r.success, r.success ? 'Pedido efetuado com sucesso' : 'Ocorreu um erro no processamento do seu pedido');
                    self.$set(self.user, 'id', r.dados.id);
                }else{
                    self.frmerror = r.error;
                }
                
            });
        },

        reset_pwd(){

        }

    },
    
    created() {
        if(this.$route.params.hash){
        
            this.$set(this, 'hash', this.$route.params.hash);
            
        }

    }
}
</script>