<template>
  <div v-if="!excluded">
    <nav aria-label="breadcrumb">
      <b-breadcrumb :items="tree"></b-breadcrumb>
    </nav>
  </div>
</template>
<script>
  export default {
    name: 'BreadcrumbHistory',
    props: {
      exclude: {type: Array, default: () => []}
    },
    computed: {
      excluded() {
        return this.exclude.indexOf(this.$route.path.split('/').pop()) > -1;
      },
      tree() {
        let tree =  ['Início']
          .concat(this.$route.path
            .split('/')
            .slice(1)
            .map(route => route
              .split('-')
              .map(word => word[0].toUpperCase() + word.slice(1))
              .join(' ')
            )
          );
          // console.log(tree);
          const breadcrumbItem = [];

          // let url = this.$route.path;
          

          tree.forEach(function(item,k){
            var link = "";
            if(k==0) link = '/';
            else{
              for(var i = k; i>0; i--){
                link += '/'+tree[k-i+1].toLowerCase();  
                // console.log('in: '+k+' = '+link);
              }
            }
            
            
            breadcrumbItem.push({'text': item, 'to': { path: link}})
          });
          // console.log(breadcrumbItem);
          return breadcrumbItem;

          // return tree;
      }
    }
  }
</script>
