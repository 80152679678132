<template>
    <div class="auth-page">
        <!-- <b-container v-if="frm">

        </b-container> -->
        <b-container>
            <h5 class="auth-logo">
            <!-- <i class="fa fa-circle text-primary"></i> -->
            <img :src="appConfig.options.base_url+appConfig.options.brand_logo" alt="" style="height: 75px;"  v-if="appConfig.options && appConfig.options.brand_logo">
            <img src="../../assets/imagens/travelgate_logo-h.png" alt="" style="height: 75px;" v-else="">
            <!-- <i class="fa fa-circle text-danger"></i> -->
            </h5>
            <Widget class="widget mx-auto text-center" title="" customHeader>
    
                <div class="col-12" v-if="loading"> <i class="fa fa-spin fa-spinner"></i> </div>
                <form class="mt" @submit.prevent="reset_pwd" v-if="!loading"> 
                    
                <fieldset class="col-12 row mt-5" v-if="hash && user.id">
                    <div class="formgroup col-12 row">
                    <label class="col-4" for="frmAuthPassword">Senha</label>
                    <input id="frmAuthPassword" class="col-8" v-model.lazy="user.pwd" type="password" placeholder="" required/>
                    <div class="info col-12 d-flex justify-content-end">
                        <p class="col-8">
                        </p>
                    </div>
                    </div>
        
                    <div class="formgroup col-12 row">
                    <label class="col-4" for="frmAuthPasswordConfirm">Confirme a Senha</label>
                    <input id="frmAuthPasswordConfirm" class="col-8" v-model.lazy="confirm_fields.pwd" type="password" placeholder="" required/>
                    <div class="info col-12 d-flex justify-content-end">
                        <p class="col-8"></p>
                        <p class="col-8 error" v-if="frmerror.pwd"><em>As senhas introduzidas não coincidem.</em></p>
                        
                    </div>
                    </div>
                </fieldset>
                <div class="col-12 row justify-content-center">
                    <div class="col-12 text-center">
                        <b-button type="submit" size="sm" class="auth-btn my-5" variant="inverse">Alterar Senha</b-button>
                    </div>

                    <div class="col-12 text-center">
                        <!-- <router-link class="d-block text-center mt-4" to="/login">Regressar ao login</router-link> -->
                    </div>
                </div>
                
                

                </form>
                <p class="widget-auth-info">
                
                </p>
                
            </Widget>
        </b-container>
        <footer class="auth-footer">
            2020 &copy; {{ (appConfig.options) ? appConfig.options.support_team : ''}}
        </footer>
    </div>
</template>
<script>
    export default {
        name: 'ResetSenha',
        components: {},
        data() {
            return {
                loading: true,
                frmerror:{},

                user: {},
                hash: {},
                confirm_fields: {},
            }
        },
        computed: {
            hash_id (){
                return null
            },
            hash_confirmation (){
                return null
            }
        },
        watch: {
            'user.pwd': function (){
                this.validateConfirmFields();
            },
            confirm_fields: {
                deep: true,
                handler() {this.validateConfirmFields()}
            }
        },
        methods: {
            validateConfirmFields(){
                let self = this;
                // console.log('entrei na validação');
                Object.keys(this.confirm_fields).forEach(k => {
                    let v = self.confirm_fields[k];

                    if(v.length > 0){
                        if(v != self.user[k]){
                            // console.log(v+' != '+self.user[k]);
                            self.frmerror[k]= true;
                        }else{
                            // console.log(v+' == '+self.user[k]);
                            if(self.frmerror[k]) { self.$delete(self.frmerror, k);}
                        }
                    }else{
                        // console.log(v+' == '+self.user[k]);
                        self.frmerror[k]= false;
                        if(self.frmerror[k]) { self.$delete(self.frmerror, k); }
                    }
                })

            },

            reset_pwd(){
                let self = this;

                this.api.post('users/auth/reset_pwd', this.user).then( (r) => {
                    console.log(r);
                    
                    self.frmLog(r.success, r.success ? 'Senha alterada com Sucesso' : 'Ocorreu um erro no processamento do seu pedido');
                    
                    if(r.success){    
                        self.$router.push('/login');
                    }else{
                        self.frmerror = r.error;
                    }
                    
                });
            }

        },
        
        created() {
            let self = this;
            let hash = (this.$route.params.hash) ? this.$route.params.hash : false;
            let uid = (this.$route.params.uid) ? this.$route.params.uid : false; 

            if(uid && hash){
                this.api.post('users/auth/recover_account_checkhash', {uid: uid, hash: hash}).then( (r) => {
                    self.loading = false;
                    console.log(r);
                    if(r.success){
                        self.$set(self.user,'id', uid);
                        self.$set(self,'hash', hash);
                        self.$set(self.user,'hash', hash);
                    }else{
                        self.$router.push('/error');
                    }
                });
            }else{
                self.$router.push('/error');
            }



        }
    }
</script>