<template>
    <div class="atendimento">
        <b-row>
            <b-col xs="12">
            <Widget
                bodyClass="widget-table-overflow"
                customHeader
            >
                <h6  class="title mb-3">
                    {{cs.attendancy_type}} (Recebido via {{cs.channel}}) 
                    <i class="fa fa-trash fa-pull-right text-danger ml-3" @click="delete_cs" style="cursor: pointer;"> Eliminar Registo</i>
                    <i class="fa fa-pencil fa-pull-right" @click="$router.push({ path: '/atendimento/'+cs.id+'/edit', params: {} })" style="cursor: pointer;"> Editar Registo</i>
                    
                </h6>
                <p style="font-size:12px;">Atendimento aberto há {{duration}} <span class="ml-5 float-right"><small>Criado a {{cs.creation_date}} </small></span></p>
                
    
    
            </Widget>
            </b-col>
        </b-row>
    
        <b-row>
            <b-col xs="12">
                <Widget>

                    <div class="row d-flex align-items-stretch justify-items-left">
                        <div class="col-xs-12 col-sm-12 col-md-4 " id="dados_cliente" style="background: rgba(250,250,250,0.8)">
                            <div class="row d-flex align-items-top">

                                <div class="col-12 mb-2 py-2">
                                    <p class="title">  Detalhes Cliente</p>
                                </div>
                                <div class="col-12" v-if="cs.client">
                                    <p> <i class="fa fa-user mr-3"></i>{{cs.client.name}}    </p>
                                    <p> <i class="fa fa-envelope mr-3"></i> {{cs.client.email}}   </p>
                                    <p> <i class="fa fa-phone mr-3"></i> {{cs.client.phone}}   </p>
                                </div>
                            
                            </div>

                        </div>


                        <div class="col-xs-12 col-sm-12 col-md-8 ">
                            <div id="frm_pedidoinfo" v-if="cs.attendancy_type == 'Pedido Informações'" >
                                
                                <p><strong>{{cs.rq.subject}}</strong></p>
                                <p class="mt-3">{{cs.rq.details}}</p>
                            </div>

                            <div id="frm_pedidoviagem" v-if="cs.attendancy_type == 'Pedido de Viagem'">
                                <p><strong>Viagem {{cs.rq.tipo}} <span v-if="cs.rq.tipo == 'Corporate'">({{cs.rq.account}})</span></strong></p>
                                <p><strong>Destino: </strong> {{cs.rq.destino}} </p>
                                <p><strong>Datas: </strong> De {{cs.rq.data_inicio}} a {{cs.rq.data_fim}} </p>
                                <p><strong>NPax: </strong> 
                                    <span class="ml-2 mr-3" v-if="cs.rq.npax.adult >0">{{cs.rq.npax.adult}} Adulto(s)</span> 
                                    <span class="ml-2 mr-3" v-if="cs.rq.npax.children >0">{{cs.rq.npax.children}} Criança(s)</span> 
                                    <span class="ml-2 mr-3" v-if="cs.rq.npax.infant >0">{{cs.rq.npax.infant}} Infant(s)</span> 
                                            
                                </p>

                                <p v-if="cs.rq.rq.voos == 1 || cs.rq.rq.alojamento == 1 || cs.rq.rq.rent_a_car == 1">
                                    <strong>Incluir: </strong> 
                                    <span class="ml-2 mr-3" v-if="cs.rq.rq.voos == 1"><i class="fa fa-plane"></i> Voo</span> 
                                    <span class="ml-2 mr-3" v-if="cs.rq.rq.alojamento == 1"><i class="fa fa-hotel"></i> Alojamento</span> 
                                    
                                    <span class="ml-2 mr-3" v-if="cs.rq.rq.rent_a_car == 1"><i class="fa fa-car"></i> Rent-a-Car</span> 
                                    
                                    <span class="ml-2 mr-3" style="color:#990000;" v-if="cs.rq.rq.alojamento == 1 && cs.rq.rq.voos == 1"><em><i class="fa fa-shield"></i> Requer Seguro de Viagem</em></span> 
                                </p>

                                <div class="col-12 row">
                                    <div class="col-4 m-0 p-0" v-if="cs.rq.rq.voos == 1">
                                        <p><em>#Preferencias Voos</em></p>
                                        <p class="pl-4" style="font-size: 11px">
                                            Classe: {{cs.rq.prefs.voos.classe_voo}} <br>
                                            Lugar: {{cs.rq.prefs.voos.lugar_preferencial}} <br>
                                            Ida: {{cs.rq.prefs.voos.horario_voo_ida}} <br> 
                                            Regresso: {{cs.rq.prefs.voos.regresso}} <br> 
                                            Obs: {{cs.rq.prefs.voos.obs}} <br> 
                                        </p>
                                    </div>


                                    <div class="col-4 m-0 p-0" v-if="cs.rq.rq.alojamento == 1">
                                        <p><em>#Preferencias Alojamento</em></p>
                                        <p class="pl-4" style="font-size: 11px;">
                                            Tipo Hotel: {{cs.rq.prefs.alojamento.tipo_hotel}} <br>
                                            Regime Prefencial: {{cs.rq.prefs.alojamento.regime_alojamento}} <br> 
                                        </p>
                                    </div>

                                    

                                    <div class="col-4 m-0 p-0" v-if="cs.rq.rq.rent_a_car == 1">
                                        <p><em>#Preferencias Rent-a-Car</em></p>
                                        <p class="pl-4" style="font-size: 11px;">
                                            Tipo Veículo: {{cs.rq.prefs.rent_a_car.tipo_veiculo}} <br>
                                            Obs: {{cs.rq.prefs.rent_a_car.obs}} <br> 
                                        </p>
                                    </div>
                                </div>

                                <p><strong>Observações: </strong>  {{cs.rq.obs}}</p>
                                    

                            </div>

                            <div id="frm_posvenda" v-if="cs.attendancy_type == 'Pós-Venda'">
                                <p><strong>{{cs.rq.file}}</strong></p>
                                <p><strong>{{cs.rq.subject}}</strong></p>
                                <p class="mt-3">{{cs.rq.details}}</p>
                                
                            </div>

                            <div id="frm_pedidosuporte" v-if="cs.attendancy_type == 'Suporte'">
                                <p><strong>FILE {{cs.rq.file}}</strong></p>
                                <p><strong>{{cs.rq.subject}}</strong></p>
                                <p class="mt-3">{{cs.rq.details}}</p>
                            </div>

                            <div id="frm_reclamacao" v-if="cs.attendancy_type == 'Reclamação / Sugestão'">
                                
                                <p><strong>{{cs.rq.subject}}</strong></p>
                                <p class="mt-3">{{cs.rq.details}}</p>
                            </div>
                        </div>
                        
                    </div>

                </Widget>
            </b-col>
        </b-row>

        <!-- ACTIONS -->
        <b-row>
            <b-col>
                <Widget>
                    <div class="row col-12">
                        <div class="col-12 text-center actions">
                            <b-button v-b-modal.add-contact style="color: #FFF;"><i class="fa fa-envelope"></i> Registar novo Contacto</b-button>
                        </div>

                                        <!-- MODAL CONTACTO -->
                                        <b-modal  size="xl" centered scrollable hide-footer :ok-disabled="true" :cancel-disabled="true" id="add-contact" title="Registar novo Contacto">
                                            <form class="col-12 row p-0" @submit.prevent="addFollowUp" >

                                                <div class=" col-12 align-items-start">
                                                    <label for="" class="col-4">Canal</label>
                                                    <select name="" id="" class="col-8" v-model="contact.type">
                                                        <option value="telefone">Telefone</option>
                                                        <option value="sms">SMS</option>
                                                        <option value="email">Email</option>
                                                        <option value="outro">Outro</option>
                                                    </select>
                                                </div>
                                                <div class=" col-12 align-items-start">
                                                    <label for="" class="col-4 align-self-start"></label>
                                                    <textarea rows="10" type="text" class="col-8" v-model="contact.contact" placeholder="Registar Contacto" required></textarea>
                                                </div>
                        
                                                <div class="col-12 text-center pt-3">
                                                    <button type="submit" class="col-12 btn border bg-1" v-if="!saving">Guardar</button>
                                                    <span class="col-12 btn border bg-1" v-if="saving"><i class="fa fa-spin fa-spinner"></i></span>
                                                </div>
                        
                                            </form>
                                        </b-modal>
                    </div>
                </Widget>
            </b-col>
        </b-row>

        <b-row>

            <!-- MAIN -->
            <b-col xs="12" sm="12" md="6" lg="8">
            <Widget>
            <div class=" panel p-0 m-0">
                <tabs>
                    <tab class="col" name="Contactos" :title="(cs.contact_log ? cs.contact_log.length : 0) +' Contacto(s)'" :selected="true">
                        <ContactLog :contact_log = 'cs.contact_log'></ContactLog>
                    </tab>
                    <tab class="col" name="Orçamentos" :title="(cs.quotes ? cs.quotes.length : 0) +' Orçamento(s)'">
                        <Quotes :quotes = 'cs.quotes'></Quotes>
                    </tab>
                    <tab class="col" name="Log" title="" v-if="$isAdmin() ">
                        <Log :cs='cs'> </Log>
                    </tab>
                </tabs>
            </div>
            </Widget>
            </b-col>



            <!--SIDEBAR-->
            <b-col xs="12" sm="12" md="6" lg="4">
                <Widget>
                    <!-- ESTADO :: {{cs}} -->
                    <select class="col-12 text-center xmultiselect__input" v-model="cs.status_id" @change="changeStatus" id="">
                        <option v-for="opt in cs_status_list" :value="opt.id" :key="opt.id" :selected="cs.status_id === opt.id"> {{opt.status}} </option>
                        <!-- <option value="0" :selected="cs.status_id === 0"> Novo </option>
                        <option value="1" :selected="cs.status_id === 1"> Aberto </option>
                        <option value="2" :selected="cs.status_id === 2"> A aguardar resposta do Cliente </option>
                        <option value="3" :selected="cs.status_id === 3"> Adiado </option>
                        
                        <option value="5" :selected="cs.status === 5"> Encerrado </option> -->
                    </select>

                    <form class="d-flex mt-5" v-if="defer_cs" @submit.prevent="submit_defer_cs">
                        <input class="col-9" type="date" v-model="cs.defered_until" required :min="moment().add(1, 'day').format('YYYY-MM-DD')" id=""> <button class="btn col-3" type="submit"> Ok </button>
                    </form>

                    <div class="text-center mt-3" v-if="this.cs.status_id == 3 && !defer_cs">
                        Atendimento reagendado para <strong>{{this.cs.defered_until | date}}</strong>
                    </div>
                </Widget>
                <Widget>
                    
                    <h3 style="font-size:12px;">Atribuição de Atendimento </h3>
                    <!-- <multiselect class="col-12" v-model="cs.assigned_to" :options="agents"></multiselect> -->
                    <multiselect class="" v-model="agents_assigned" selectLabel="" deselectLabel="x" :options="agents_list" :multiple="true" :close-on-select="true" :clear-on-select="false" :preserve-search="false" placeholder="" label="name" value="name" track-by="name" :preselect-first="false" :hideSelected="true" open-direction="bottom" :taggable="false" @select="assign_agent">
                        <template class="col-12" slot="selection" slot-scope="{ values, search, isOpen }">
                            <span class="multiselect__single" v-if="values.length && !isOpen">{{ values.length }} agente(s) atribuído(s)</span>
                        </template>
                        <!-- <template slot="tag" slot-scope="props">{{props}}</template> -->
                    </multiselect>
                    
                    
                    <!-- <pre class="mt-5 language-json"><code>{{ cs.assigned_to  }}</code></pre> -->

                    <!-- <pre class="mt-5 language-json"><code>{{ agents_assigned  }}</code></pre> -->
                    <div class="mt-5 row">
                        <div class="col-12 p-0 d-flex  align-items-center" v-for="agent in agents_assigned" :key="agent.id">
                            <figure class="round" style="width: 40px; height:40px; border-radius: 40px; overflow: hidden;">
                                <img :src="agent.profile_picture" :alt="agent.name" style="width:100%">
                            </figure>
                            <div class="col align-items-center">{{agent.name}}</div>
                            <div class="col-3 align-items-center flex-end d-flex text-right" style="text-align:right; "> 
                                    <i class="fa fa-trash ml-2" v-if="agent.id != cs.owner_id" @click="remove_agent(agent.id)" style="cursor:pointer;"></i>
                                    
                                    <i class="fa fa-star ml-2" v-if="agent.id == cs.owner_id" title="Comercial Responsável pelo Atendimento"></i>
                                    <i class="fa fa-star-o ml-2" v-if="$isAdmin() && agent.id != cs.owner_id" @click="change_owner(agent.id)" style="cursor: pointer;"></i>
                            </div>
                            <!-- <pre class="col-12">
                                {{agent}}
                            </pre> -->

                            
                            
                        </div>
                    </div>
                </Widget>
            </b-col>
        </b-row>
    </div>
    </template>
    
    <script>  
    import ContactLog from './cs_view/Atendimento-contactlog';
    import Quotes from './cs_view/Atendimento-quotes';
    import Log from './cs_view/Atendimento-log';
    
    
    import Multiselect from 'vue-multiselect'
    export default {
    name: 'Atendimento',
    components: {
        ContactLog,
        Quotes,
        Log,
        Multiselect,
    },
    data() {
        return {
            saving: false,
            cs_status_list: {},
            defer_cs: false,
            cs_loaded: false,
            cs_id: 0,
            cs: {},
            agents_list: [],
            agents_assigned: [],

            //quote
            quote: {},

            //contacts
            contact: {},

            //email
            email: {}
        };
    },
    beforeCreate() {
        let self = this;
        this.api.get('atendimento/list_cs_status').then(function(response){
                    
                    if(response.success){
                        self.cs_status_list = response.dados;
                        self.$forceUpdate();
                    }else{
                        console.log(response.error);
                    }
                });  

        this.api.get('users/list_comercial_agents').then(function(response){
                    
                    if(response.success){
                        self.agents_list = response.dados;
                        self.$forceUpdate();
                    }else{
                        console.log(response.error);
                    }
                });  
    },
    created() {

        let self = this;
        
        if(this.$route.params.id) {this.cs_id = this.$route.params.id;}
        
        // this.load_data();


        this.api.get('atendimento/get/'+this.cs_id).then(function(response){
                    
                    if(response.success){
                        self.cs = response.dados;
                        // console.log(self.cs);
                        // self.cs.rq = JSON.parse(self.cs.rq);
                        // if(!self.cs.quotes){self.cs.quotes = [];}
                        // if(!self.cs.contact_log){self.cs.contact_log = [];}
                        if(!self.cs.assigned_to){self.cs.assigned_to = [];}
                        else{self.cs.assigned_to = JSON.parse(self.cs.assigned_to);}
                        console.log(self.cs.assigned_to);
                        if(!self.cs.assigned_to.includes(self.cs.owner_id) ){
                                self.cs.assigned_to.push(self.cs.owner_id);
                                self.assign_agent({id: self.cs.owner_id});
                        }
                        
                        //Prepara a lista de agentes envolvidos no atendimento:
                        self.agents_assigned = self.cs.assigned_to.map((agent_id)=>{ var tmp = self.agents_list.filter( (agent) => { if(agent.id == agent_id) return agent;} ); return tmp[0] ? tmp[0] : {}})
                        
                        //Se atendimento criado por agente não comercial, remove da lista: 
                        self.agents_assigned.forEach((agent,k) => {if(!agent.id){self.agents_assigned.splice(k,1);} });
                        self.agents_assigned.sort((a,b) => a.name > b.name ? 1 : -1);
                        
                        self.cs_loaded = true;
                        self.$forceUpdate();
                    }else{
                        this.$router.push('/atendimento');
                        console.log(response.error);
                    }
                });

        
    },
    mounted() {
        
        // //##### TESTE SWAL
        // this.swal.fire({
        //     icon: 'success',
        //     title: 'Signed in successfully',
        //     showConfirmButton: false, 
        //     toast: false,
        //     timer: 3000,
        //     position: 'center'
        // })


    },
    filters: {
        date(val) { return val ? window.moment(val).format('DD-MM-YYYY') : '' }
    },
    watch: {
        cs: function(){
            console.log(this.cs);
        },
        agents_assigned: function(){
            
            // let self = this;
            var tmp = this.cs.assigned_to;
            
            this.cs.assigned_to = this.agents_assigned.map((agent)=>{return agent.id});
            const equals = (a, b) => a.length === b.length && a.every((v, i) => v === b[i]);

            if(!equals(tmp, this.cs.assigned_to)){
                
                // this.api.post('atendimento/updateAssignedAgents', {cs_id: self.cs.id, assigned_agents: self.cs.assigned_to}).then(function(response){
                //     if(self.cs_loaded == true){self.frmLog(response.success, response.success ? 'Dados Atualizados com sucesso' : 'Ocorreu um erro no processamento do seu pedido');}
                //     console.log(response);
                // });
            }

        }
    },
    methods: {

        delete_cs(){
                var self = this;
                this.api.delete('atendimento/'+this.cs_id ).then(function(){
                    self.$router.push('/atendimento');
                });
        },


        //STATUS
        changeStatus(){
            //alert('Novo Estado de atendimento: : '+this.cs.status_id);
            let self = this;
            if(this.cs.status_id == 3){ 
                this.defer_cs = true; 
            }else{
                    this.api.post('atendimento/updateStatus', {'cs_id': this.cs.id, 'status_id': this.cs.status_id} ).then(function(response){
                        self.frmLog(response.success, response.success ? 'Dados Atualizados com sucesso' : 'Ocorreu um erro no processamento do seu pedido');
                        console.log(response);      
                });
            }
            
        },

        submit_defer_cs(){
                    var self = this;
                    this.api.post('atendimento/updateStatus', {'cs_id': this.cs.id, 'status_id': this.cs.status_id, 'defered_until': this.cs.defered_until} ).then(function(response){
                        self.frmLog(response.success, response.success ? 'Dados Atualizados com sucesso' : 'Ocorreu um erro no processamento do seu pedido');
                        console.log(response); 
                        self.cs.status_id = 3;
                        self.defer_cs = false;   
                    });
                    
        },

        //AGENTS
        assign_agent(agent){

            let self = this;
            this.api.post('atendimento/AssignAgent', {cs_id: self.cs.id, agent_id: agent.id}).then(function(response){
                if(self.cs_loaded == true){
                    self.frmLog(response.success, response.success ? 'Dados Atualizados com sucesso' : 'Ocorreu um erro no processamento do seu pedido');
                }
                console.log(response);
            });
        },
        remove_agent(agent_id){
            let self = this;
            let agent_index = this.agents_assigned.map(item => item.id).indexOf(agent_id);
            this.agents_assigned.splice(agent_index, 1);
            this.api.post('atendimento/RemoveAgent', {cs_id: self.cs.id, agent_id: agent_id}).then(function(response){
                if(self.cs_loaded == true){
                    self.frmLog(response.success, response.success ? 'Dados Atualizados com sucesso' : 'Ocorreu um erro no processamento do seu pedido');
                }
                console.log(response);
            });
        },
        change_owner(agent_id){
            let self = this;
            
            this.api.post('atendimento/ChangeCSOwner', {cs_id: self.cs.id, agent_id: agent_id}).then(function(response){
                if(self.cs_loaded == true){
                    self.frmLog(response.success, response.success ? 'Dados Atualizados com sucesso' : 'Ocorreu um erro no processamento do seu pedido');
                    self.cs.owner_id = agent_id;
                }
                console.log(response);
            });
        },


        //FOLLOWUP
        addFollowUp(){
            var self = this;

            self.saving = true;
            var data = self.contact;
            data.cs_id = self.cs.id;
            
            self.api.post('atendimento/register_followup', data).then(function(response){
                console.log(response);

                if(response.success){
                    self.cs.contact_log = response.dados;
                    self.$bvModal.hide('add-contact');
                    self.$forceUpdate();    
                }

                self.saving = false;
                
            });

        }
    },
    computed: {
        duration(){
            var a = this.cs.closed ? window.moment(this.cs.closed_date) : window.moment();
            var b = window.moment(this.cs.creation_date);
            var diff = a.diff(b, 'days')   // =1
        
            if(diff < 1 ){
                diff = a.diff(b, 'hours')   // =1
                return diff + ' horas';
            }else{
                return diff + ' dias';
            }   
        }
    }
    };
    </script>
    
    <style lang="css" scoped>
        /* h3{color:#900;} */
        .atendimento >>> .multiselect__tag{width: 0px; height: 0px; overflow: hidden; display: none !important;}
    </style>
    <style lang="scss">
        /* .multiselect__tag{width: 0px; height: 0px; overflow: hidden; display: none !important;} */
    </style>
    