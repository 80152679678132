<template>
    <div class="componente">
        <transition name="router-animation">
            <router-view :options="options" @updatedata="load_data"/>
        </transition>
    </div>
</template>
    
<script>
    export default {
    name: 'Componente',
    components: {},
    data() {
        return {
            options: {},
        };
    },
    computed: {
    },
    methods: {
        load_data(){
            this.load_options();
        },
        load_options(){
            let self = this;
            
            // self.api.post('tad/load_options', {}).then((r)=>{
            //     console.log(r);
            //     if(r.success){
            //         self.$set(self.options, 'loaded', true);
            //     }
            // });
            self.$set(self.options, 'loaded', true);
        }
    },
    beforeMount() {
    },
    mounted() {   
        this.load_data();
    },
    created() {

    }
}
</script>
    