<template>
<div class="new-campaign-page">
    <b-row>
        <Widget class="my-5">
            <div class="row">
                <div class="col-8">
                    <p style="font-size: 20px;">Campanha <strong>{{campaign.title}}</strong></p>
                    <p style="font-size: 20px;">canal <strong>{{campaign.channel}}</strong></p>
                </div>
                <div class="col-4" v-if="campaign.channel == 'sms'">
                    <p class="text-right" style="font-size: 16px;"> <i class="fa fa-info"></i> Saldo SMS</p>
                    <p class="text-right" style="font-size: 18px;"> <strong>{{balance.saldo}} {{balance.currency}}</strong> </p>
                    <p class="text-right" style="font-size: 12px;"> <strong>aprox. {{balance.msgs_left}} sms</strong> </p>
                </div>
            </div>
            
        </Widget>
    </b-row>
    <b-row>
        <b-col xs="12" md="12" lg="12">
        <tabs>
            <tab class="col" name="Resumo" :title="'Resumo Campanha: '" >
                <Resumo v-if="true == false"></Resumo>
            </tab>
            <tab class="col" name="Contactos" :title="'Enviar Campanha: ' + rows.length + ' envios pendentes.'" :selected="(followUp_closed.length > 0) ? false : true">
                <Envios v-if="true == false"></Envios>
                <b-row>
                        <b-col xs="8" md="8" lg="8">
                            <div class="pb-xlg h-100">
                            <Widget class="h-100 mb-0" xtitle="Capanhas Ativas" xclose>
                                    <span v-if="!campaign.contacts"> <i class="fa fa-spin fa-spinner"></i> </span>
                                    <div v-if="campaign.contacts"> 
                                        <datatable class=" m-0 p-0" 
                                            :selectable="false" 
                                            :hastableactions="true" 
                                            :searchable="true" 
                
                                            :selectedRows="campaign.selected_contacts"
                                            @update_selectedRows="selectionChanged"
                                            
                                            :rows="rows"     
                                            :columns="[{label:'', field:'selection', sortable: false}, {label:'Nome', field:'name'}, {label:'Telefone', field:'phone'}, {label:'Contactado', html: 'true', field:'number_of_contacts', isBool: true}, {label:'OV', field:'ovs', isBool: true}]" 
                                            :initialsort = "[{field: 'name', type: 'asc'}]"
                                        /> 
                                        <pre>{{ campaign.selected_contacts }}</pre>
                                    </div>
                            </Widget>
                            </div>
                        </b-col>    
                        
                        <b-col xs="4" md="4" lg="4">
                            <div class="pb-xlg h-100">
                                <Widget class="h-100 mb-0" xtitle="Capanhas Ativas" xclose>
                
                                        <div class="fieldset row mt-3" v-if="campaign.selected_contacts">
                                            {{campaign.selected_contacts.length || 0}} contactos seleccionados
                                        </div>
                
                                        <form @submit.prevent="send_campaign">
                                            <div class="fieldset row">
                                                <label for="message" class="col-12 m-0 p-0">Mensagem</label>
                                                <textarea v-model="campaign.message" class="col-12" cols="30" rows="10"></textarea>
                                                
                                            </div>
                                            
                                            
                                            <div class="fieldset row mt-3">
                                                <label for="landing_page_url" class="col-12 m-0 p-0">Link a direccionar</label>
                                                <input class="col-12" type="text" placeholder="https://www.travelgate.pt/landing-page" v-model="campaign.landing_page_url">
                                            </div>
                
                                            <div class="fieldset row mt-3">
                                                <span :class="smslength > 160 ? 'text-danger' : 'text-success'">{{smslength}} / 160 caracteres</span>
                                            </div>
                                            <div class="fieldset col-12 mt-3 text-center">
                                                <button type="submit" v-if="!saving">Enviar</button>
                                            </div>
                                        </form>
                                </Widget>
                            </div>
                        </b-col>
                </b-row>
            </tab>
            <tab class="col" name="FollowUp" :title="'Follow Up dos envios: ' + followUp_closed.length + ' processos em aberto'"  :selected="(followUp_closed.length >0) ? true : false">
                    <FollowUp :rows="followUp" @reload="load_data"></FollowUp>
            </tab>
        </tabs>
        </b-col>

    </b-row>
</div>
</template>

<script>

import Envios from './tabs/Campanha_Envios';
import FollowUp from './tabs/Campanha_Followup';
import Resumo from './tabs/Campanha_Resumo';

import Widget from '@/components/Widget/Widget';

export default {
name: 'NovaCampanha',
components: {
    Envios,
    FollowUp,
    Resumo,
    Widget 
},
data() {
    return {
        balance: {},
        sc: [],
        error_log: {}, 
        saving: false,
        campaign: {},
        sent_contacts: [],
        rowSelection: []
    };
},
computed:{
    followUp_closed(){
        return this.sent_contacts.filter((ct) => {return ct.closed == 0 });
    },
    followUp(){
        return this.sent_contacts;
        // return this.sent_contacts.filter((ct) => {return ct.closed == 0 });
    },
    rows(){
        let ct = this.campaign.contacts.filter((ct) => {return ((ct.number_of_contacts == 0 || !ct.number_of_contacts) && ct.phone) });
        return ct.sort((a, b) => { return a.name - b.name;});
        // return this.campaign.contacts;
    },
    smslength(){
        let msglength = this.campaign.message ? this.campaign.message.length : 0;
        let urllength = this.campaign.landing_page_url ? this.campaign.landing_page_url.length : 0;
        return msglength + urllength;
    }
},
created() {
        if(this.$route.params.id) {this.campaign.id = this.$route.params.id;}
        this.load_data();
},
methods: {
    load_data(){
        let self = this;
        self.saving = true;

        self.api.post('MKT/Egoi/myAccount').then((r)=>{
            if(r.success){
                self.$set(self.balance ,'saldo', r.dados.balance)
                self.$set(self.balance ,'currency', r.dados.currency);
                self.$set(self.balance ,'msgs_left', r.dados.balance / 0.04);
                
                console.log(r);
            }
        });


        this.api.post('MKT/Campanhas/list/', {id:self.campaign.id}).then(function(response){
            // console.log(response);
            self.campaign = response.dados;

            self.api.post('MKT/Campanhas/get_contact_history/', {campaign_id: self.campaign.id, target: self.campaign.target}).then(function(response){
                // console.log(response);
                self.sent_contacts = response.dados;            
            });

            self.api.post('MKT/Campanhas/get_contacts/', {campaign_id: self.campaign.id, target: self.campaign.target}).then(function(response){
                console.log(response);
                self.$set(self.campaign, 'contacts', response.dados);
                self.$set(self.campaign, 'selected_contacts', []);
            
            });

            self.saving = false;
            self.$forceUpdate();
        });

    },
    selectionChanged(params) {
            this.campaign.selected_contacts = params;
    },
    save_campaign(){
            let self = this;

            self.saving = true;

            this.api.post('MKT/Campanhas/save',this.campaign).then(function(response){

                console.log(response);
                self.saving = false;
        });

            
    },

    send_campaign(){
        // this.save_campaign();
        let self = this;
        self.saving = true;



        // this.api.post('MKT/Egoi/shortenUrl', {url: this.campaign.landing_page_url}).then(function(response){
        //     console.log(response);
        // });


        // this.api.post('MKT/Egoi/accountSettings').then(function(response){
        // console.log(response);
        // });


        this.api.post('MKT/Campanhas/send', this.campaign).then(function(response){

                console.log(response);
                
                
                self.load_data();
                // self.api.post('MKT/Campanhas/get_contacts/', {campaign_id: self.campaign.id, target: self.campaign.target}).then(function(response){
                //     // console.log(response);

                //     self.$set(self.campaign, 'contacts', response.dados);
                //     self.$set(self.campaign, 'selected_contacts', []);
                
                // });

        });


    }
},

};
</script>
