<template>
    <div class="dashboard">
        <b-row style="width:50%; float: right; margin-top: -50px; margin-bottom: 40px;">
            <b-col class="text-right"> 
                
                    <button class="btn btn-primary" @click="$router.push({ path: '/componente/novo', params: {} })">  Adicionar </button>
                
            </b-col>
        </b-row>


        <b-row class="col-12 mt-3">
            <b-col>
                <p>Lista</p>
            </b-col>
        </b-row>

        <b-row class="col-12 mt-3">
            <b-col>
                <datatable  class=" m-0 p-0" 
                            :searchable="true"
                            :rows="data"
                            :columns="[{label: 'ID', field: 'id', sortable:false}]"
                            @rowclick="rowclick_callback" 
                            :initialsort = "[{field: 'start_date', type: 'asc'}]"
                            
                >

                </datatable>
            </b-col>
        </b-row>
        

        <!-- <b-row class="col-12 mt-3">
            <b-col>
                <pre>{{data}}</pre>
            </b-col>
        </b-row> -->
    </div>
</template>
    
<script>
    export default {
    name: 'List',
    components: {},
    data() {
        return {
            data:[]
        };
    },
    computed: {

    },
    methods: {
        rowclick_callback(value){
            //console.log(value);
            // let self = this;
            if(value.action == 'link') this.$router.push({ path: '/componente/'+value.row.id, params: {} });
        },
    },
    
    beforeMount() {
    },
    mounted() {   
    },
    created() {
        let self = this; 

        //LOAD DATA
        self.data.push({id:1});

    }
}
</script>
    