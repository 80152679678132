<template>
    <div>
        <b-row>
        <b-col :xs="(selectedRow.id) ? 6 : 12" :md="selectedRow.id ? 6 : 12" :lg="selectedRow.id ? 6 : 12">
            <datatable v-if="!selectedRow.id" class=" m-0 p-0" 
                :selectable="false" 
                :rowclickselect="true"
                :hastableactions="true" 
                :searchable="true" 

                :selectedRows="s"

                @rowclick="rowclick_callback" 
            
                :rows="contacts"     
                :columns="[ {label:'', field:'selection', sortable: false}, {label:'Nome', field:'name'}, {label:'Email', field:'email'}, {label:'Telefone', field:'phone'}, {label:'Abriu Link', html: 'true', field:'link_opened', isBool: true}, {label:'Autoriza contacto',  html: 'true', field:'authorize_contact', isBool: true}, {label:'Registos Followup', field:'followup_actions'}, {label:'Encerrado', field:'closed', isBool: true},  {label:'OV', field:'generated_ov', isBool: true}]" 
                :initialsort = "[{field: 'closed', type: 'asc'}, {field: 'link_opened', type: 'desc'}, {field: 'followup_actions', type: 'desc'}]"
            /> 
            <datatable v-if="selectedRow.id" class=" m-0 p-0" 
                :selectable="false" 
                :rowclickselect="true"
                :hastableactions="true" 
                :searchable="true" 

                :selectedRows="s"

                @rowclick="rowclick_callback" 
            
                :rows="contacts"     
                :columns="[ {label:'', field:'selection', sortable: false}, {label:'Nome', field:'name'}, {label:'Abriu', html: 'true', field:'link_opened', isBool: true}, {label:'Autoriza Ct.',  html: 'true', field:'authorize_contact', isBool: true}, {label:'Followup', field:'followup_actions'}, {label:'Encerrado', field:'closed', isBool: true},  {label:'OV', field:'generated_ov', isBool: true}]" 
                :initialsort = "[{field: 'closed', type: 'asc'}, {field: 'link_opened', type: 'desc'}, {field: 'followup_actions', type: 'desc'}]"
            /> 
        </b-col>
        <b-col xs="6" md="6" lg="6" v-if="selectedRow.id">
            <div class="col-12 mt-5 pt-5">
                <p class="head mb-5">Contacto a {{selectedRow.date}}<i class="fa fa-times fa-pull-right" @click="clearselection"></i></p>
                <div class="col-12 p-0 mt-2" id="cli_data">
                    <p><strong>Nome: </strong>{{selectedRow.name}}</p>
                    <p><strong>Email: </strong>{{selectedRow.email}}</p>
                    <p><strong>Telefone: </strong>{{selectedRow.phone}}</p>
                </div>
                <hr>
                <div class="col-12 p-0 mt-2" id="ct_details">
                    <p><strong>Seguiu link: </strong><i class="fa" :class="(selectedRow.link_opened) ? 'fa-check text-success' : 'fa-times text-danger'"></i> <span v-if="selectedRow.link_opened">{{selectedRow.open_date}}</span> </p>
                    <p><strong>Ações de Followup: </strong>{{selectedRow.followup_actions}}</p>
                </div>
            </div>

            <div v-if="selectedRow.closed">
                <div class="card" v-if="selectedRow.generated_ov">
                    <div class="card-body text-center">
                        OV #{{selectedRow.generated_ov}}
                    </div>
                </div>
                <div class="card" v-if="!selectedRow.generated_ov">
                    <div class="card-body text-center">
                        Lead Perdida
                    </div>
                </div>
            
            </div>

            <div v-if="!selectedRow.closed">
                <div class="col-12 text-center d-fex flex-row justify-content-between my-5" v-if="!selectedRow.generated_ov">
                    
                    <button class="col-6 col-lg-4 mx-0 mx-lg-3 btn btn-danger" @click="close_lost">Encerrar sem OV</button>
                    <button class="col-6 col-lg-4 mx-0 mx-lg-3 btn btn-success" @click="close_won">Gerar OV</button>
                    
                </div>
                <div class="card col-12 text-center d-fex flex-row flex-wrap p-0 my-5" v-if="!selectedRow.closed && !selectedRow.generated_ov">
                    <div class="card-header col-12 m-0 p-0">Registar Folowup</div>
                    <div class="card-body col-12">
                    <form class="col-12 row p-0" @submit.prevent="addFollowUp" >

                        <div class=" col-12 align-items-start">
                            <label for="" class="col-4">Canal</label>
                            <select name="" id="" class="col-8" v-model="newFollowUp.channel">
                                <option value="telefone">Telefone</option>
                                <option value="sms">SMS</option>
                                <option value="email">Email</option>
                                <option value="outro">Outro</option>
                            </select>
                        </div>
                        <div class=" col-12 align-items-start">
                            <label for="" class="col-4 align-self-start"></label>
                            <textarea type="text" class="col-8" v-model="newFollowUp.action" placeholder="Registar Ação" required></textarea>
                        </div>

                        <div class="col-12 text-center pt-3">
                            <button type="submit" class="col-12 btn border bg-1" v-if="!saving">Guardar</button>
                            <span class="col-12 btn border bg-1" v-if="saving"><i class="fa fa-spin fa-spinner"></i></span>
                        </div>

                    </form>
                    </div>
                    
                </div>
            </div>
            <div class="col-12 text-center d-fex flex-row my-5" >
                <!-- <pre>{{selectedRow.followup_log}}</pre>         -->

                <div class="col-12 p-0 m-0 text-left" v-for="(fu, k) in selectedRow.followup_log.reverse()" :key="k">
                    <div class="card-header col-12 m-0 p-0">{{fu.creation_date}} <br> <small><em>por: {{fu.agent}} via {{fu.channel}} </em></small></div>
                    <div class="card-body col-12 my-3">
                        {{fu.action}}
                    </div>
                </div>
                
            </div>
            
        </b-col>
        </b-row>
        <!-- 
        <pre>
            {{s}}
        </pre>    
        <pre>
            {{contacts}}
        </pre> 
        -->
    </div>
</template>

<script>
export default {
name: 'FollowUp',
props: ['rows'],
components: {
},
data() {
    return {
        saving: false,
        s: [],
        selectedRow: {},
        newFollowUp: {}
    }
},
created() {
},
watch: {
},
filters: {
},
methods: {
    
    addFollowUp(){
        var self = this;

        self.saving = true;
        var data = self.newFollowUp;
        data.campaign_id = self.selectedRow.campaign_id;
        data.contact_id = self.selectedRow.id;
        self.api.post('MKT/Campanhas/register_followup/', data).then(function(response){
            console.log(response);

            if(response.success){
                self.selectedRow.followup_log.push(response.dados);

                let indexOfRow = self.contacts.findIndex(i => i.id === self.selectedRow.id);
                self.selectedRow.followup_actions ++;
                self.contacts[indexOfRow].followup_actions ++;
                
                self.newFollowUp = {};

                self.saving = false;
                self.$forceUpdate();    
                
            }


            
        });

    },


    close_won(){this.close(true);},
    
    close_lost(){this.close(false);},

    close(won = false){

        var ov_id = null;
        let self = this;

        self.selectedRow.won = won;
        // this.api.post('MKT/Campanhas/close_lead/', {id:self.selectedRow.id, won: won}).then(function(response){
        this.api.post('MKT/Campanhas/close_lead/', self.selectedRow).then(function(response){
            console.log(response);
                    ov_id = response.dados;
                    self.$set(self.selectedRow, 'generated_ov', ov_id);
                    
                    let indexOfRow = self.contacts.findIndex(i => i.id === self.selectedRow.id);
                    self.selectedRow.closed = true ;
                    self.contacts[indexOfRow].closed = true;

            if(won){
                
                return ov_id
            }else{
                    
                // self.selectedRow = {}; 
                // self.s = []; 
                // self.$emit.reload;
            }
        });

                

        
    },


    get_followup(){
        let self = this;
        this.api.post('MKT/Campanhas/get_followup/', {contact_id:self.selectedRow.id}).then(function(response){
                    // console.log(response);
                    self.$set(self.selectedRow, 'followup_log', response.dados);
                    self.$forceUpdate();
                });
    },

    rowclick_callback(ev){
        // console.log(ev);
        let self = this;
        self.s = [];
        let indexOfRow = self.contacts.findIndex(i => i.id === ev.row.id);
        self.s.push(self.contacts[indexOfRow]);
        self.selectedRow = ev.row;
        // self.$emit('reload');
        self.selectedRow.followup_log = [];
        if(self.selectedRow.followup_actions > 0){
                self.get_followup();
        }

    },
    clearselection(){
        this.$emit('reload');
        this.s = []
        this.selectedRow = []
    }
},
computed: {
    
    contacts:{
        get: function() {return this.rows},
        set: function() {}
    }
}
};
</script>

<style scoped>

</style>