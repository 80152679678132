<template>
<div class="auth-page">
    <!-- <pre>
    STEP
    {{frmStep}}
    </pre>
    <pre>
    Is New Account
    {{newAccount}}
    </pre>
    <pre>
    Hash Account
    {{hashAccount}}
    </pre>
    -->

    <!--
    <pre>
    Account
    {{account}}
    </pre>
    <pre>
    Profile
    {{profile}}
    </pre> 

    -->
    <b-container v-if="!enabled">
        <div class="row">
            <div class="col-12 text-center my-5" style="font-size: 24px;">
                <a href="/login">instalação inicial efetuada. Regressar ao login</a>
            </div>
        </div>
    </b-container>
    <b-container v-if="enabled">
    <h5 class="auth-logo" style="display:none">
        <!-- <i class="fa fa-circle text-primary"></i> -->
        <img src="../../assets/imagens/travelgate_logo-h.png" alt="Travel Gate" style="height: 75px;">
        <!-- <i class="fa fa-circle text-danger"></i> -->
    </h5>
    <Widget class="widget mx-auto text-center" title="" customHeader>

        <!-- <form class="mt" @submit.prevent="null"> -->

        <!-- Passo 1: Info  -->
        <div class="row mt-5 py-5" v-if="frmStep == 1">
            <step01 @next="frmStep=2" @previous="frmStep=1" :info="info"></step01>
        </div>

        <!-- Passo 2: Info Account (SU USER ) -->
        <div class="row mt-5 py-5" v-if="frmStep == 2">
            <step02 @next="frmStep=3" @previous="frmStep=1" :profile="profile"></step02>
        </div> 
        
                <!-- Passo 3: CONFIRMACAO -->
                <div class="row mt-5 py-5" v-if="frmStep == 3">
                    <step03 @previous="frmStep=1"></step03>
                </div> 
                
        

        <!-- NAV EMPRESA -->
        <!-- 
        <div id="frm-nav" class="col-12 border-top mt-5 pt-5" v-if="frmNav && frmStep >= 1 && frmStep < 4">

            <div class="col-6 pull-left" v-if="frmStep > 1"> 
                <button class="btn" @click="frmStep -= 1"><i class="fa fa-chevron-left mr-3"></i> Anterior </button>
            </div>
            <div class="col-6 pull-right" v-if="frmStep < maxStep"> 
                <button class="btn" @click="frmStep += 1">Seguinte <i class="fa fa-chevron-right ml-3"></i> </button>
            </div>
        
        </div> 
        -->

    
        
    </Widget>
    </b-container>
    <footer class="auth-footer">
        {{moment().format("YYYY")}} &copy; {{appConfig.options.support_team}}
    </footer>
</div>
</template>

<script>
import Widget from '@/components/Widget/Widget';

import Step01 from './steps/step01_info.vue';
import Step02 from './steps/step02_account.vue';
import Step03 from './steps/step03_confirmation.vue';

export default {
name: 'Install',
components: { Widget, Step01, Step02, Step03 },
data() {
    return {
        enabled: false,
    frmNav: true,
    frmStep: 1,
    info:{},
    profile: {},
    frmError: null,
    };
},
computed: {
    maxStep () {
    return 2
    },
    errorMessage () {
    return null
    }
},
methods: {
    next(){
    if(this.frmStep < this.maxStep) this.frmStep ++;
    },
    previous(){
    if(this.frmStep > 1) this.frmStep --;
    },
    selectAccountType (accountType) {
    //alert(accountType);
    this.account.accountType = accountType;
    if(accountType === 'personal'){
        this.frmStep = 4;
    }else{
        this.frmStep = 2;
    }
    
    },
    isNewAccount (isNew) {
    //alert('Nova empresa : '+isNew);
    this.newAccount = isNew;
    this.frmStep = 3;
    this.$forceUpdate()
    },
    setAccountId (accountId) {
    this.account = accountId;
    },
    registo() {
    // const email = this.$refs.email.value;
    // const password = this.$refs.password.value;
    // const profile = {name: 'Utilizador Exemplo', email: email, pwd: password};

    // if (email.length !== 0 && password.length !== 0) {
    //   window.localStorage.setItem('authenticated', true);

    //   window.localStorage.setItem('jwt', 'exemplo de token de sessão');
    //   window.localStorage.setItem('profile', JSON.stringify(profile));
        
    //   this.$router.push('/dashboard');
    // }
    

    },
},
created() {

    //query de parametros tipo GET e.g.: http://localhost:8080/registo?hash=123
    if(this.$route.query.hash){
    alert(this.$route.query.hash) 
    }

    //query de parametros tipo Smart URL e.g.: http://localhost:8080/registo/corporate/123
    if(this.$route.params.hash){
    alert('Empresa ' + this.$route.params.hash) 

    //Exemplo de chamada ao registo com hash de empresa
        this.account.id = this.$route.params.hash;
        this.frmStep = 4;

    }

},
mounted() {
    let self = this;
    this.api.get('sys/config').then( (r) => {
                self.appConfig.options={}; 
                if(r.dados){ r.dados.forEach((v) => {self.$set(self.info, v.key, v.value);}); }
                if(!self.info.installed){self.enabled = true}
                self.$forceUpdate();
            });
}
};
</script>

<style type="less" scoped>
.auth-page{
    width: 100vw;
    min-height: 100vh;
    margin-bottom: 100px;
    background: url(../../assets/imagens/Business-Travel-Banner-brilho.jpg); 
    background-size: cover; 
    background-position:center;
    position: fixed;
    overflow: hidden;
    overflow-y: scroll;
    
}
.widget{
    background-color: rgba(255,255,255, 0.9);
}
</style>