import Vue from 'vue';
import Router from 'vue-router';
// import store from '@/store/index.js'

import Layout from '@/components/Layout/Layout';

import Install from '@/pages/Install/Install';
import Config from '@/pages/Config/Config';


import Login from '@/pages/Login/Login';
import Registo from '@/pages/Login/Registo';
import RecuperarSenha from '@/pages/Login/RecuperarSenha';
import ResetPwd from '@/pages/Login/ResetPwd';

import ErrorPage from '@/pages/Error/Error';
import Dashboard from '@/pages/Dashboard/Dashboard';

import SampleComponent from '@/pages/_Component/_Routes';
import TAD from '@/pages/TAD/_Routes';

import UserProfile from '@/pages/User/Profile';

import Atendimento from '@/pages/Atendimento/Atendimento';
import AtendimentoForm from '@/pages/Atendimento/Atendimento-form';
import AtendimentoView from '@/pages/Atendimento/Atendimento-view';
import PedidoViagem from '@/pages/PedidoViagem/FormPedidoViagem';


import Marketing from  '@/pages/Marketing/Marketing';
import Campanha_Dashboard from  '@/pages/Marketing/Campanha_Dashboard';
import Campanha_FRM from  '@/pages/Marketing/Campanha_FRM';


//TEMP
    // Core
    import TypographyPage from '@/pages/Template/Typography/Typography';
    // Tables
    import TablesBasicPage from '@/pages/Template/Tables/Basic';
    // Maps
    import GoogleMapPage from '@/pages/Template/Maps/Google';
    // Main
    import DashboardOriginal from '@/pages/Template/Dashboard/DashboardOriginal';
    // Charts
    import ChartsPage from '@/pages/Template/Charts/Charts';
    // Ui
    import IconsPage from '@/pages/Template/Icons/Icons';
    import NotificationsPage from '@/pages/Template/Notifications/Notifications';


Vue.use(Router);

const routes = [
  {
    path: '/install',
    name: 'Install',
    component: Install,
    meta: {
      requiresAuth: false
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      requiresAuth: false
    },
  },
  {
    path: '/recuperar-senha/:uid/:hash',
    name: 'Recuperar Senha',
    component: ResetPwd,
    meta: {
      requiresAuth: false
    },
  },
  {
    path: '/recuperar-senha',
    name: 'Definir Nova Senha',
    component: RecuperarSenha,
    meta: {
      requiresAuth: false
    },
  },
  {
    path: '/registo/corporate/:hash',
    name: 'RegistoCorporate',
    component: Registo,
    meta: {
      requiresAuth: false
    },
  },
  {
    path: '/registo',
    name: 'Registo',
    component: Registo,
    meta: {
      requiresAuth: false
    },
  },
  {
    path: '/error',
    name: 'Error',
    component: ErrorPage,
  },
  {
    path: '/',
    name: 'Layout',
    component: Layout,
    meta: {
      requiresAuth: true
    },
    children: [
      ...SampleComponent,
      ...TAD,
      {
        path: 'dashboard',
        name: 'Dashboard',
        component: Dashboard,
        meta: {
          // requiresAuth: true
        },
      },
      {
        path: 'user',
        name: 'Perfil',
        component: UserProfile,
        meta: {
          // requiresAuth: true
        },
      },
      {
        path: 'atendimento',
        name: 'Atendimento',
        component: Atendimento,
        meta: {
          // requiresAuth: false
        },
      },
      {
        path: 'atendimento/novo',
        name: 'AtendimentoForm',
        component: AtendimentoForm,
        meta: {
          // requiresAuth: true
        },
      },
      {
        path: 'atendimento/:id',
        name: 'AtendimentoView',
        component: AtendimentoView,
        meta: {
          // requiresAuth: true
        },
      },
      {
        path: 'atendimento/:id/edit',
        name: 'AtendimentoEdit',
        component: AtendimentoForm,
        meta: {
          // requiresAuth: true
        },
      },
      {
        path: 'pedido-viagem',
        name: 'Pedido de Viagem',
        component: PedidoViagem,
        meta: {
          // requiresAuth: true
        }

      },
      {
        path: 'marketing',
        name: 'Campanhas',
        component: Marketing,
        meta:{
          // requiresAuth: true
        }
      },
      {
        path: 'marketing/nova_campanha',
        name: 'Nova Campanha',
        component: Campanha_FRM,
        meta: {
          // requiresAuth: true
        },
      },
      {
        path: 'marketing/:id',
        name: 'Campanha',
        component: Campanha_Dashboard,
        meta: {
          // requiresAuth: true
        },
      },


      {
        path: 'config',
        name: 'Config',
        component: Config,
        meta: {
          // requiresAuth: true
        },
      },
      

      //TEMP
      {
        path: 'dashboard-original',
        name: 'AnalyticsPage',
        component: DashboardOriginal,
        meta: {
          requiresAuth: true
        },
      },
      {
        path: 'typography',
        name: 'TypographyPage',
        component: TypographyPage,
      },
      {
        path: 'components/icons',
        name: 'IconsPage',
        component: IconsPage,
      },
      {
        path: 'notifications',
        name: 'NotificationsPage',
        component: NotificationsPage,
      },
      {
        path: 'components/charts',
        name: 'ChartsPage',
        component: ChartsPage,
      },
      {
        path: 'tables',
        name: 'TablesBasicPage',
        component: TablesBasicPage,
      },
      {
        path: 'components/maps',
        name: 'GoogleMapPage',
        component: GoogleMapPage,
      },
    ],
  },
]

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  // console.log('de')
  // console.log(from)
  // console.log('para')
  // console.log(to)
  
  if(to.path === '/' || to.path === ''){
    next({
      // path: '/dashboard',
      path: '/atendimento',
      params: { nextUrl: to.fullPath }
    })
  }else{ 
    if (!to.matched.some(record => record.path)) {
      next({
        path: '/error',
        params: { nextUrl: to.fullPath }
      })
    }
  }

  
  //Valida se route requer autenticação
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (window.localStorage.getItem('authenticated') == false) {
      // store.state.jwt = null
      next({
        path: '/login',
        params: { nextUrl: to.fullPath }
      })
    } else {
      
      let user = {}
      if (window.localStorage.getItem('user') === null) {
        // alert('vamos ao login')
        next({
          path: '/login',
          params: { nextUrl: to.fullPath }
        })  
      }else{
        // console.log(window.localStorage.getItem('user'))
      }
      
      if (to.matched.some(record => record.meta.require_admin)) {
        if (user.require_admin) {
          next()
        } else {
          next()
        }
      } else {
        next()
      }
    }
  } else if (to.matched.some(record => record.meta.guest)) {
    if (window.localStorage.getItem('jwt') == null) {
      next()
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
