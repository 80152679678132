<template>
    <div>

        <div class="row mb-4">
            <div class="col-12 row">
                <div class="col-12">
                    <pre>{{cs}}</pre>
                </div>
            </div>
        </div>

        
    </div>
</template>

<script>

export default {
name: 'Log',
props: ['cs'],
components: {
},
data() {
    return {
        //declaração de variáveis locais
        
    };
},
created() {
    
},
mounted() {
    
},
watch: {
    
},
filters: {
    
},
methods: {

},
computed: {

}
};
</script>

<style scoped></style>