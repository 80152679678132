<template>
<div :class="[{root: true, sidebarClose, sidebarStatic}, 'sing-dashboard']">
  <Sidebar />
  <!-- <Helper /> -->
  <div class="wrap">
    <Header />
    <v-touch class="content" @swipe="handleSwipe" :swipe-options="{direction: 'horizontal'}">
      <breadcrumb-history></breadcrumb-history>
      <transition name="router-animation">
        <router-view />
      </transition>
      <footer class="contentFooter text-right">
        <!-- @ Powered by <a href="https://oriceservices.com" rel="nofollow noopener noreferrer" target="_blank">Orice Services </a> -->
        </footer>
    </v-touch>
  </div>
</div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
const { mapState, mapActions } = createNamespacedHelpers('layout');

import Sidebar from '@/components/Sidebar/Sidebar';
import Header from '@/components/Header/Header';
// import Helper from '@/components/Helper/Helper';
import BreadcrumbHistory from '@/components/BreadcrumbHistory/BreadcrumbHistory';

import './Layout.scss';

export default {
  name: 'Layout',
  components: { Sidebar, Header, /*Helper,*/ BreadcrumbHistory },
  methods: {
    ...mapActions(['switchSidebar', 'handleSwipe', 'changeSidebarActive', 'toggleSidebar'],
    ),
    handleWindowResize() {
      const width = window.innerWidth;

      if (width <= 768 && this.sidebarStatic) {
        this.toggleSidebar();
        this.changeSidebarActive(null);
      }
    },
  },
  computed: {
    ...mapState(["sidebarClose", "sidebarStatic"]),
  },
  created() {
    
    
    if(!this.appConfig.options){
        let self = this;
        
      
          this.api.get('sys/config').then( (r) => {
            self.appConfig.options={}; 
            if(r.dados){
              r.dados.forEach((v) => {
                self.$set(self.appConfig.options, v.key, v.value);
                // console.log(v.key+ ' = ' + v.value);
              });

             

            }
            self.$forceUpdate();
              if(!self.appConfig.options.installed){
                  self.$router.push('/install');
                }/*else{
                  self.$router.push('/login');
                }*/

              if(self.appConfig.options.sitename){
                //CHANGE DOCUMENT TITLE
                document.title = self.appConfig.options.sitename;
              }
              if(self.appConfig.options.brand_icon){
                //CHANGE DOCUMENT FAVICON
                var link = document.querySelector("link[rel~='icon']");
                if (!link) {
                    link = document.createElement('link');
                    link.rel = 'icon';
                    document.getElementsByTagName('head')[0].appendChild(link);
                }
                link.href = self.appConfig.options.base_url+self.appConfig.options.brand_icon;
              }
              
              self.$forceUpdate();
          });



      }

      this.$forceUpdate();




    const staticSidebar = JSON.parse(localStorage.getItem('sidebarStatic'));

    if (staticSidebar) {
      this.$store.state.layout.sidebarStatic = true;
    } else if (!this.sidebarClose) {
      setTimeout(() => {
        this.switchSidebar(true);
        this.changeSidebarActive(null);
      }, 2500);
    }

    this.handleWindowResize();
    window.addEventListener('resize', this.handleWindowResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleWindowResize);
  }
};
</script>

<style src="./Layout.scss" lang="scss" />
