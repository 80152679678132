var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sidebar-wrapper"},[_c('nav',{class:{sidebar: true, sidebarStatic: _vm.sidebarStatic, sidebarOpened: _vm.sidebarOpened},on:{"mouseenter":_vm.sidebarMouseEnter,"mouseleave":_vm.sidebarMouseLeave}},[_c('div',{staticClass:"d-flex flex-column",staticStyle:{"height":"100%"}},[_c('header',{staticClass:"logo"},[_c('router-link',{attrs:{"to":"/dashboard"}},[_c('span',{staticClass:"primary-word"},[_c('img',{staticStyle:{"height":"45px"},attrs:{"src":require("../../assets/imagens/tg-icon.png"),"alt":"Travel Gate"}})]),_vm._v(" "),_c('img',{staticStyle:{"height":"35px"},attrs:{"src":require("../../assets/imagens/tg-logo-inv.png"),"alt":"Travel Gate"}})])],1),_c('ul',{staticClass:"nav"},[_c('NavLink',{attrs:{"activeItem":_vm.activeItem,"header":"Dashboard","link":"/dashboard","iconName":"flaticon-home","index":"dashboard","isHeader":""}}),_c('NavLink',{attrs:{"activeItem":_vm.activeItem,"header":"Atendimento","link":"/atendimento","iconName":"flaticon-calendar","index":"atendimento","isHeader":""}}),_c('NavLink',{attrs:{"activeItem":_vm.activeItem,"header":"Email","link":"/mailbox","iconName":"flaticon-paper-plane","index":"mailbox","isHeader":""}}),_c('NavLink',{attrs:{"activeItem":_vm.activeItem,"header":"Clientes","link":"/clients","iconName":"flaticon-users","index":"clients","isHeader":"","childrenLinks":[
              { header: 'Accounts', link: '/accounts' },
              { header: 'Clientes', link: '/clientes' },
              { header: 'Leads', link: '/leads' }
            ]}}),_c('NavLink',{attrs:{"activeItem":_vm.activeItem,"header":"Campanhas","link":"/marketing","iconName":"flaticon-network","index":"marketing","isHeader":""}}),_c('NavLink',{attrs:{"activeItem":_vm.activeItem,"header":"Suporte","link":"/suporte","iconName":"flaticon-settings-4","index":"suporte","childrenLinks":[
              { header: 'Pedido de Reembolso a Fornecedor', link: '/suporte/reembolso' },
              { header: 'Pedido de Reembolso a Cliente', link: '/suporte/reembolso-cliente' },
              { header: 'Pedido de Nota de Crédito', link: '/suporte/nota-de-credito' },
              { header: 'Gestão de ADMs', link: '/suporte/adms' }              
            ]}})],1),_c('div',{staticClass:"mt-auto",attrs:{"id":"bottom-menu"}},[_c('ul',{staticClass:"nav"},[_c('NavLink',{attrs:{"activeItem":_vm.activeItem,"header":"User","link":"/user","iconName":"flaticon-user","index":"user","isHeader":"","childrenLinks":[
            { header: 'Meu Perfil', link: '/user' } ]}}),_c('NavLink',{attrs:{"activeItem":_vm.activeItem,"header":"Ferramentas","link":"/ferramentas","iconName":"flaticon-settings-3","index":"ferramentas","isHeader":"","childrenLinks":[
              { header: 'Knowledge Base', link: '#1' },
              { header: 'Documentos Internos', link: '#2' },
              { header: 'Senhas e Acessos', link: '#3' }
            ]}}),_c('NavLink',{attrs:{"activeItem":_vm.activeItem,"header":"Config","link":"/config","iconName":"flaticon-app","index":"config","childrenLinks":[
              { header: 'Configurar Sistema', link: '/config' },

              { header: 'Dashboard Original', link: '/dashboard-original' },
              { header: 'Notifications', link: '/notifications' },
              { header: 'Tables', link: '/tables' },
              { header: 'Typography', link: '/typography' },
              { header: 'Charts', link: '/components/charts' },
              { header: 'Icons', link: '/components/icons' },
              { header: 'Maps', link: '/components/maps' } ]}})],1)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }