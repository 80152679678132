<template>
    <div class="m-0 p-0">
        <vue-good-table :columns="columns"
                        :rows="rows"
                        :fixed-header="true"
                        :xline-numbers="true"
                        
                        :sort-options="{
                            enabled: true,
                            initialSortBy: initialsort ? initialsort : {}
                        }"
    
                        :select-options="{ 
                            enabled: selectable,
                            selectOnCheckboxOnly: !rowclickselect,
                            selectionText: 'linhas seleccionadas',
                            disableSelectInfo: true, 
                        }"
    
                        @on-selected-rows-change="$emit('update_selectedRows', $event)"
                        
    
                        :search-options="{
                            enabled: searchable,
                            placeholder: 'Pesquisar resultados',
                        }"
                        :pagination-options="{
                            enabled: true,
    
                            perPage: 25,
                            position: 'bottom',
                            perPageDropdown: [10, 25, 50],
                            dropdownAllowAll: true,
                            //setCurrentPage: 1,
                            nextLabel: 'Seguinte',
                            prevLabel: 'Anterior',
                            rowsPerPageLabel: 'Linhas por página',
                            ofLabel: 'de',
                            pageLabel: 'página', // for 'pages' mode
                            allLabel: 'Todos',
        
                        }"
    
                        @on-row-click="onRowClick" 
                        @xxon-row-dblclick="onRowClick" 
                        
                        styleClass="col-12 m-o p-o vgt-table striped"
                    >
    
                        <div slot="table-actions" v-if="hastableactions">
                            <i class="fa fa-table"></i> 
                        </div>
                        <div slot="emptystate">
                            Sem resultados a apresentar
                        </div>
    
                        <!-- ALTERAR CABEÇALHO DE COLUNA ACTION -->
                        <template slot="table-column" slot-scope="props">
                            <div v-if="props.column.field == 'selection'"  style="width: 100%; text-align: center;">
                                <input type="checkbox" @click="toggleSelection" :checked="allSelected">
								<!-- <pre>{{'all selected ? ' + allSelected }}</pre> -->
                            </div>
							
							
							<div v-if="props.column.label == 'actions'"  style="width: 100%; text-align: center;">
                                <i class="fa fa-cog"></i> 
                            </div>
                            <span v-else>
                                {{props.column.label}}
                            </span>
                        </template>
    
                        <!-- ALTERAR CONTEUDO DE CÉLULA ACTION -->
                        <template slot="table-row" slot-scope="props">
							<div v-if="props.column.field === 'selection'" style="width: 100%; text-align: center;"  >
								<input type="checkbox" v-model="selected" :value="rows[props.row.originalIndex]">
                                <!-- <input type="checkbox" v-model="selected" :value="rows[props.row.originalIndex].id ? rows[props.row.originalIndex].id : rows[props.row.originalIndex]"> -->
								<!-- {{props.row}} {{rows[props.row.originalIndex]}} -->
							</div>
                            <div v-else-if="props.column.field == 'actions'" style="width: 100%; text-align: center;"   >
                                <button class="btn btn-danger py-0" @click="action = 'delete'" role="delete"><i class="fa fa-trash" color="#900"></i></button>
                            </div>
                            <div v-else-if="props.column.isBool" style="width: 100%; text-align: center;"   >
                                <i class="fa" :class="(boolIsTrue(props.formattedRow[props.column.field])) ? 'fa-check text-success' : 'fa-times text-danger'"></i>
                            </div>
                            
                            <span v-else @on-cell-click="onRowClick">
                                {{props.formattedRow[props.column.field]}}
                            </span>
                        </template>
        </vue-good-table>
    
<!--     
        <div>
            Linhas Seleccionadas:
            <br>
            <pre>
                {{selectedRows}}
            </pre>
        </div> 
         -->
    </div>
    </template>
    
    <script>
    export default {
    name: 'vue-datatable',
    props:{
        hastableactions:{
            type: Boolean,
            default: false,
        },
        initialsort:{
            type: Array,
            default: () => {
                return false
            },
        },
        searchable:{
            type: Boolean,
            default: false,
        },
        selectable:{
            type: Boolean,
            default: false,
        },
        rowclickselect:{
            type: Boolean,
            default: false,
        },
        selectedRows:{
            type: Array,
            default: function () {
                return []
            }
        },
        columns: {
            type: Array,
            default: function () {
                return []
            }
        },
        rows: {
            type: Array,
            default: function () {
                return []
            }
        }
    },
    data(){
        return {
            // optimizedRows: [],
            selected:[],
            action: '',
        };
    },
    mounted() {
		// console.log('mount componente');
        // console.log(this.$refs);
        this.selected = this.selection;
        // this.timeOutRows();

        // setInterval( () => { this.selection = this.selected; }, 300);

        
    },
	watch:{
		selected(){
            // this.$emit('update_selectedRows', this.selected);
            // this.selection = this.selected;
            setTimeout(() => {this.selection = this.selected;},  1000);
		}
	},
    computed: {
        
		allSelected() {
			
			return this.rows.length === this.selection.length
		},
		
		selection: {
			get: function() {
				return this.selectedRows;
			},
			set: function(value) {
				// console.log("setter called with value", value)
                
				this.$emit('update_selectedRows', value);
                
			}
		}
    },
    methods:{
        // timeOutRows() {
        //     if(this.rows.length > 1500){
        //         this.rows.forEach((c, i) => setTimeout(() => this.optimizedRows.push(c), i + 200))
        //     }else{
        //         this.$set(this,'optimizedRows', this.rows);
        //     }
            
        // },
        boolIsTrue(value){
            var r = false;
            switch(value){
                case false:
                case null: 
                case 0 : 
                case '0' : 
                case 'não' : 
                case 'no' : r = false; break;
                default : r = true; break;
            }
            return r;
        },
		toggleSelection(){
			if(this.allSelected){this.selection = []}
			else{this.selection = this.rows}
		},
        onRowClick(params) {
            
    
            // console.log(params.event.target.localName);
            if(this.$listeners && this.$listeners.rowclick){
					
                    switch (params.event.target.localName){
                        case 'button':
                        case 'i':  this.action = 'delete'; break;
                        case 'input': this.action = 'select'; break;
                        default: this.action = 'link'; break;
                    }
                        this.$emit('rowclick', {target: params.event, action: this.action, row: params.row});
            
                    this.action = '';
                    this.$forceUpdate();
            
            }
            
            // console.log(params.event);
            // console.log(params.row);
    
            // params.row - row object 
            // params.pageIndex - index of this row on the current page.
            // params.selected - if selection is enabled this argument 
            // indicates selected or not
            // params.event - click event
        },
        // selectionChanged(params){
        //     let temp = this.selected = params.selectedRows;
            
    
        //     if(this.$listeners && this.$listeners.update_selectedRows){
        //         this.$emit('update_selectedRows', temp);
				
        //     }
		// 	this.$forceUpdate();
        // },
        // onSelectAll(){
        //     alert('seleccionaste todas');
        // }
    }
    };
    </script>
    
    <style lang="scss">
        table.vgt-table{
            font-size: 13px;
        }
        .vgt-global-search{
            background: transparent;
            border:none;
        }
        .vgt-global-search__input{
            margin-bottom: 45px;
        }
        table.vgt-table{
            background-color: transparent;
            border:none;
        }
        th.vgt-checkbox-col{
            background-color: thistle;
        }
        .vgt-table thead, .vgt-table thead  th{
            border-top: solid 1px #dedede;
            border-bottom: solid 1px #dedede;
    
            padding: 20px 00px;
    
            background: transparent;
            background-color: transparent;	
            font-weight: 300;
        }
        .vgt-inner-wrap{
            box-shadow: none;
        }
        .vgt-wrap__footer{
            background: transparent;
            border:none;
        }
        .vgt-checkbox-col{
            border:none !important;
            background: transparent !important;
        }
    </style>