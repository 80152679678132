<template>
    <div class="cp">
        <b-row style="width:50%; float: right; margin-top: -50px; margin-bottom: 40px;">
            <b-col class="text-right"> 
                
                    <button class="btn btn-primary" @click="$router.push({ path: '/componente/'+id+'/edit', params: {} })">  Editar </button>
                    <button class="btn btn-danger ml-3" @click="eliminar()">  <i class="fa fa-trash"></i> </button>
                
            </b-col>
        </b-row>
        <b-row  style="width:100%;">
            <b-col xs="12" sm="12" md="8" lg="9">
                <!--  <pre>{{data}}</pre> -->                
                <Widget>

                    <div class="col-12 p-0 row">
                        <label class="col-4" for="mec_number">ID</label>
                        <div class="col-8">
                            {{id}}
                        </div>
                    </div>
    
                    <div class="col-12 p-0 row">
                        <label class="col-4" for="">TEMP</label>
                        <div class="col-8">
                            {{data.temp}}
                        </div>
                    </div>
                    
                </Widget>
            </b-col>
            <b-col xs="12" sm="12" md="4" lg="3">
                <Widget style="font-size: 12px; padding: 20px 0px;">
                    aa
                </Widget>

            </b-col>
        </b-row>


        <b-row>
            <b-col>
                <Widget>
                    aa
                </Widget>
            </b-col>
        </b-row>
        
    </div>
</template>
    
<script>
    export default {
    name: 'Single',
    components: {},
    data() {
        return {
            data: {}
        };
    },
    computed: {
        id(){
            let id = this.$route.params.id || null;
            return id;
            // return Number.isInteger(id) ? id : null
        }
    },
    methods: {
        eliminar(){
            let self = this;
            

            
            this.swal.fire({
                title: 'De certeza que pretende eliminar ?',
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: `Eliminar`,
                denyButtonText: `Cancelar`,
                }).then((result) => {
                if (result.isConfirmed) {
                    self.api.delete('tad/courses/delete_action/'+this.id).then(function(r){
                        if(r.success){ self.$router.push('/formacao/cursos-agendados');
                        }else{
                            // self.$router.push('/empresa/recursos-humanos/');
                        }
                    });


                } else if (result.isDenied) {
                    //do Nothing.
                }
                })

        
        }
    },
    
    beforeMount() {
    },
    mounted() {   
    },
    created() {
        let self = this;
        if(this.$route.params.id) {
            self.api.get('tad/courses/get_action/'+this.id).then((r)=>{
                console.log(r);
                self.$set(self, 'data', r.dados);
            });
        }else{
            self.$router.push('/formacao/cursos-agendados');
        }
    }
}
</script>
    