<template>
    <div class="atendimento">
        <form @submit.prevent="save">
        <b-row>
            <b-col xs="12">
            <Widget
                title="Registo Atendimento"
                bodyClass="widget-table-overflow"
                customHeader
            >
                
                    <fieldset class="col-12 my-5 text-center" id="channel_atendimento">
                        <label class="col-auto" for="cs_channel_balcao">
                            <input type="radio" name="cs_channel" v-model="cs.channel" value="Balcão" id="cs_channel_balcao"> Balcão
                        </label>

                        <label class="col-auto" for="cs_channel_telefone">
                            <input type="radio" name="cs_channel" v-model="cs.channel" value="Telefone" id="cs_channel_telefone"> Telefone
                        </label>

                        <label class="col-auto" for="cs_channel_email">
                            <input type="radio" name="cs_channel" v-model="cs.channel" value="Email" id="cs_channel_email"> Email
                        </label>

                        <label class="col-auto" for="cs_channel_skype">
                            <input type="radio" name="cs_channel" v-model="cs.channel" value="Skype" id="cs_channel_skype"> Skype
                        </label>

                        <label class="col-auto" for="cs_channel_chatwebsite">
                            <input type="radio" name="cs_channel" v-model="cs.channel" value="Chat Website" id="cs_channel_chatwebsite"> Chat Website
                        </label>

                        <label class="col-auto" for="cs_channel_redessociais">
                            <input type="radio" name="cs_channel" v-model="cs.channel" value="Redes Sociais" id="cs_channel_redessociais"> Redes Sociais
                        </label>

                        <label class="col-auto" for="cs_channel_outro">
                            <input type="radio" name="cs_channel" v-model="cs.channel" value="Outro" id="cs_channel_outro"> Outro
                        </label>
                    </fieldset>
            </Widget>
            </b-col>
        </b-row>
    


        <b-row>
            <b-col xs="12">
                <Widget class="pb-5"
                    title='Dados Cliente '
                    bodyClass="widget-table-overflow"
                    customHeader
                >

                        
                        <div id="cliactions" class="py-2 mt-3" v-if="new_cli_form == true || cs.client.id">
                            <i v-if="cs.client.id" class="ml-3 fa fa-pencil fa-pull-right" @click="edit_cli = !edit_cli" style="cursor:pointer; "> {{ (edit_cli) ? 'Editar dados de Cliente' : 'Fechar Modo Edição' }}</i>
                            <i v-if="new_cli_form == true || cs.client.id" class="ml-3 fa fa-times fa-pull-right" @click="reset_client" style="cursor:pointer; "> Limpar dados de Cliente</i>
                            <hr class="col-12" style="margin-top: 20px;">
                        </div>
                        
                    <!-- <div class="formgroup col-12 my-5 row">
                        <input class="col" type="text" placeholder="Pesquisar Cliente" /> <i class="col-1 fa fa-search"></i>
                        
                    </div> -->


                    <!-- <multiselect class="" v-model="agents_assigned" selectLabel="" deselectLabel="x" :options="agents_list" :multiple="true" :close-on-select="true" :clear-on-select="false" :preserve-search="false" placeholder="" label="name" value="name" track-by="name" :preselect-first="false" :hideSelected="true" open-direction="bottom" :taggable="true" @select="select_client">
                        <template class="col-12" slot="selection" slot-scope="{ values, search, isOpen }">
                            <span class="multiselect__single" v-if="values.length && !isOpen">{{ values.length }} agente(s) atribuído(s)</span>
                        </template>
                        <template slot="tag" slot-scope="props">{{props}}</template>
                    </multiselect> -->

                    <div class="col-12 pt-5 " v-if="error_log.client_id && !cs.client.id">
                        <p class="error"> Por favor seleccione um cliente para este registo </p>
                    </div>

                    <div class="py-5" v-if="!new_cli_form && !cs.client.id">
                        
                        <multiselect v-model="search.selected" open-direction="bottom" :options="search.list" label="name" track-by="name" :multiple="false" 
                                    :taggable="true" 
                                    :searchable="true" :loading="search.isLoading" :internal-search="false" :clear-on-select="true" :close-on-select="true" :options-limit="300" :limit="3" :max-height="600" :show-no-results="true" :hide-selected="false"
                                        
                                        placeholder="Pesquisar Cliente"
                                        tag-placeholder="Adicionar novo Cliente" 
                                        selectLabel="Selecionar Cliente"
                                        noOptions ="Sem resultados"
                                        
                                        @search-change="search_client"
                                        @tag="add_client"
                                        @select="select_client"
                                    >
                                    <!-- <span slot="noResult">Oops! No elements found. Consider changing the search query.</span> -->
                                    <template slot="noOptions"> Pesquisar por clientes (nome, email ou telefone) </template>
                                    <template slot="option" slot-scope="props">
                                        <!-- <img class="option__image" :src="props.option.img" alt="No Man’s Sky"> -->
                                        <div class="option__desc">
                                            <span class="option__title">{{(props.option.id) ? props.option.id + ' - ' : ' '}} {{ props.option.name }}</span> 
                                        </div>
                                    </template>

                        </multiselect>

                                            <!-- <p class="mt-4">Seleccionaste o cliente :: </p>
                                            <pre class="language-json"><code>{{ cs.cli  }}</code></pre>

                                            <p class="mt-4">OU adiciona novo cliente:: </p>
                                            <pre class="language-json"><code>{{ new_cli  }}</code></pre> -->
                    </div>
                    



                    <!-- NEW CLIENT FORM -->
                    <div class="col-12 row m-0 p-0" v-if="new_cli_form">
                        <form class="col-12 row m-0 p-0" @submit.prevent="save_client" autocomplete="off">

                            <div class="formgroup col-12 mt-4 row">
                                <label class="col-12 col-md-4" for="client_name">Nome</label>
                                <div class="col-12 col-md-8 p-0 m-0">
                                    <input class="col-12" type="text" id="client_name" v-model="new_cli.name" required>
                                    <p class="error" v-if="error_log.new_cli.name">Por favor preencha este campo</p>
                                </div>
                            </div>

                            <div class="formgroup col-12 mt-4  row">
                                <label class="col-12 col-md-4" for="client_email">Email</label>
                                <div class="col-12 col-md-8 p-0 m-0">
                                    <input class="col-12" type="email" id="client_email" v-model="new_cli.email" required>
                                    <p class="error" v-if="error_log.new_cli.email">Por favor preencha este campo</p>
                                </div>
                            </div>

                            <div class="formgroup col-12 mt-4  row">
                                <label class="col-12 col-md-4" for="client_telephone">Telefone</label>
                                <div class="col-12 col-md-8 p-0 m-0">
                                    <input class="col-12" type="text" id="client_telephone" v-model="new_cli.phone">
                                    <p class="error" v-if="error_log.new_cli.phone">Por favor preencha este campo</p>
                                </div>
                            </div>
                            <div class="col-12 my-5 text-center">
                                <button class="btn-success px-3 py-2 " type="submit" v-if="!saving">Registar novo Cliente</button>
                                <span class="btn-success" v-if="saving"><i class="fa fa-spinner fa-spin"></i></span>
                            </div>
                        </form>
                    </div>


                    <!-- CLIENT PROFILE -->
                    <div class="col-12 row" v-if="cs.client.id">

                        <div class="formgroup col-12 mt-4 row d-none">
                            <label class="col-12 col-md-4" for="client_name">ID</label>
                            <input class="col-12 col-md-8" type="text" id="client_name" v-model="cs.client.id" required disabled>
                        </div>


                        <div class="formgroup col-12 mt-4 row">
                            <label class="col-12 col-md-4" for="client_name">Nome</label>
                            <input class="col-12 col-md-8" type="text" id="client_name" v-model="cs.client.name" required :disabled="edit_cli">
                        </div>

                        <div class="formgroup col-12 mt-4  row">
                            <label class="col-12 col-md-4" for="client_email">Email</label>
                            <input class="col-12 col-md-8" type="email" id="client_email" v-model="cs.client.email" required :disabled="edit_cli">
                        </div>

                        <div class="formgroup col-12 mt-4  row">
                            <label class="col-12 col-md-4" for="client_telephone">Telefone</label>
                            <input class="col-12 col-md-8" type="text" id="client_telephone" v-model="cs.client.phone" :disabled="edit_cli">
                        </div>
                </div>


                


                </Widget>
            </b-col>
        </b-row>

        <b-row>
            <b-col xs="12">
                <Widget>

                        


                            <fieldset id="dados_cliente">


                            </fieldset>

                            
                            <fieldset class="col-12 my-5 text-center" id="tipo_pedido">
                                <label class="col-2" for="cs_type_info">
                                    <input type="radio" name="tipo_pedido" v-model="cs.attendancy_type" value="Pedido Informações" id="cs_type_info"> Pedido de Informações
                                </label>

                                <label class="col-2" for="cs_type_pedidoviagem">
                                    <input type="radio" name="tipo_pedido" v-model="cs.attendancy_type" value="Pedido de Viagem" id="cs_type_pedidoviagem"> Pedido de Orçamento
                                </label>

                                <label class="col-2" for="cs_type_posvenda">
                                    <input type="radio" name="tipo_pedido" v-model="cs.attendancy_type" value="Pós-Venda" id="cs_type_posvenda"> Serviço Pós-Venda
                                </label>

                                <label class="col-2" for="cs_type_suporte">
                                    <input type="radio" name="tipo_pedido" v-model="cs.attendancy_type" value="Suporte" id="cs_type_suporte"> Pedido de Suporte
                                </label>

                                <label class="col-2" for="cs_type_reclamacao">
                                    <input type="radio" name="tipo_pedido" v-model="cs.attendancy_type" value="Reclamação / Sugestão" id="cs_type_reclamacao"> Reclamação / Sugestão
                                </label>


                            </fieldset>

                            <fieldset id="frm_pedidoinfo" v-if="cs.attendancy_type == 'Pedido Informações'" >
                                <frm-simple-request :at="cs.rq"></frm-simple-request>
                            </fieldset>

                            <fieldset id="frm_pedidoviagem" v-if="cs.attendancy_type == 'Pedido de Viagem'">
                                <frm-pedido-viagem :at="cs.rq"></frm-pedido-viagem>
                            </fieldset>

                            <fieldset id="frm_posvenda" v-if="cs.attendancy_type == 'Pós-Venda'">
                                <frm-postsale-request :at="cs.rq"></frm-postsale-request>
                            </fieldset>

                            <fieldset id="frm_pedidosuporte" v-if="cs.attendancy_type == 'Suporte'">
                                <frm-postsale-request :at="cs.rq"></frm-postsale-request>
                            </fieldset>

                            <fieldset id="frm_reclamacao" v-if="cs.attendancy_type == 'Reclamação / Sugestão'">
                                <frm-simple-request :at="cs.rq"></frm-simple-request>
                            </fieldset>


                            <fieldset id=""></fieldset>



                </Widget>
            </b-col>
        </b-row>

        <b-row>
            <b-col xs="12">
                <Widget>

                    <div class="col-12 row d-flex justify-content-center text-center">
                        <button class="btn btn-primary col-12 py-3" type="submit" v-if="!saving">Guardar</button>
                        <span class="btn btn-primary col-12 py-3" v-if="saving" disabled><i class="fa fa-spin fa-spinner"></i> A guardar dados </span>
                    </div>
                    <!-- <pre>{{cs}}</pre> -->

                </Widget>
            </b-col>
        </b-row>
        </form>
    </div>
    </template>
    
    <script>  
    import FrmPedidoViagem from './cs_form/FrmPedidoViagem';
    import FrmSimpleRequest from './cs_form/FrmSimpleRequest';
    import FrmPostsaleRequest from './cs_form/FrmPostSaleRequest';
    import Multiselect from 'vue-multiselect'

    export default {
    name: 'Atendimento',
    components: {
        FrmPedidoViagem,
        FrmSimpleRequest,
        FrmPostsaleRequest,
        Multiselect
    },
    data() {
        return {
            error_log: {},

            search: {},
            client_list: [],
            search_cli: [],

            new_cli_form: false,
            new_cli: {},

            edit_cli: false,

            saving: false,
            cs: {}
        };
    },
    watch: {
        'cs.attendancy_type': function(){
            if(!this.$route.params.id) {
            this.$set(this.cs,'rq',{});
            }
        },
    },
    created() {
        let self = this;
        let user = window.jsonxDec(window.localStorage.getItem('user'));

        this.$set(this.search,'isLoading', false);
        this.$set(this.search,'list', []);
        this.$set(this.search,'selected', null);

        this.$set(this.error_log, 'new_cli', {});
        


        if(this.$route.params.id) {
            this.cs_id = this.$route.params.id;
            this.api.get('atendimento/get/'+this.cs_id).then(function(response){
                if(response.success){
                    self.cs = response.dados;
                    
                    // self.$set(self.cs, 'rq', JSON.parse(self.cs.rq));
                    console.log(self.cs.rq);
                }
            });
        }else{
            this.$set(this.cs,'owner_id',user.id);
            this.$set(this.cs,'balcao_id',user.department_id);        
                
            this.$set(this.cs,'status_id',1);        
            this.$set(this.cs,'client',{});        
            this.$set(this.cs,'channel','Balcão');        
            this.$set(this.cs,'attendancy_type','Pedido Informações');        
            this.$set(this.cs,'rq',{});
        }
        
        
    },
    methods: {

        //CLIENT HANDLING - SEARCH
                    search_client(query){
                        var self = this;
                        console.log(query);
                        self.search.isLoading = true;
                        self.search.selected = null;
                        if(query && query.length){
                            // this.api.get('clientes/search/'+query).then(function(response){
                            this.api.post('clientes/search',{s:query}).then(function(response){
                                console.log(response);
                                
                                self.$set(self.search, 'list', (response.dados != null) ? response.dados : []);
                                self.$set(self.search, 'isLoading', false);
                                
                            });
                        }else{
                            self.$set(self.search, 'list', []);
                            self.$set(self.search, 'isLoading', false);
                        }

                    },
                    select_client(cli){
                        this.search.selected = null;
                        this.$set(this.cs,'client',cli);
                    },

                    add_client (querystring) {
                        console.log(querystring);
                        this.new_cli_form = true;
                        this.$set(this.new_cli, 'name', querystring);

                        this.$forceUpdate();
                    },

                    reset_client(){
                        this.search.selected = null;
                        this.new_cli_form = false; 
                        this.cs.client = {}
                        this.$forceUpdate;
                    },


        //CLIENT FORMS
        save_client(){
            let self = this;
            self.error_log.new_cli = {};

            self.saving = true;
            this.api.post('clientes/save', this.new_cli).then( (response) => {

                console.log('SAVE CLIENT LOG:::::::');
                console.log(response);


                if(response.success){
                    
                    self.$set(self.cs, 'client', response.dados);
                    self.new_cli_form = false;

                }else{
                    self.error_log.new_cli = response.error; 
                }


                self.saving = false;
                
                self.$forceUpdate();

            } );

        },



        //REQUEST HANDLING
        save(){
            let self = this;
            self.$forceUpdate();
            self.error_log = [];

            if(!self.cs.client.id){
                self.$set(self.error_log, 'client_id', true);
            }else{
                
                self.saving = true;
                this.api.post('atendimento/save',this.cs).then(function(response){
                    
                    if(response.success){
                        self.$router.push('/atendimento/'+response.dados);
                    }else{
                        console.log(response.error);
                    }
                    self.saving = false;
                });
            }


        }
    },
    computed: {
    
    }
    };
    </script>
    
    <style lang="scss" scoped>
        input:disabled{
            background: transparent;
            border: none;
        }
    </style>
    