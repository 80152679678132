import { render, staticRenderFns } from "./Atendimento-log.vue?vue&type=template&id=173549c5&scoped=true&"
import script from "./Atendimento-log.vue?vue&type=script&lang=js&"
export * from "./Atendimento-log.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "173549c5",
  null
  
)

export default component.exports