// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import config from '@/config';

import Vue from 'vue';
import BootstrapVue from 'bootstrap-vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import moment from 'moment';
import 'moment/locale/pt';
//   const moment= require('moment') ;

import VueSweetAlert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';



// import * as VueGoogleMaps from 'vue2-google-maps';
import VueTouch from 'vue-touch';
// import Trend from 'vuetrend';
// import Toasted from 'vue-toasted';
// import VueApexCharts from 'vue-apexcharts';

import store from './store';
import router from './Routes';
import App from './App';
import layoutMixin from './mixins/layout';
import Widget from './components/Widget/Widget';

import tabs from './components/Tabs/Tabs';
import tab from './components/Tabs/single-tab';


import VueGoodTablePlugin from 'vue-good-table';
import 'vue-good-table/dist/vue-good-table.css'

Vue.use(VueGoodTablePlugin);
import Datatable from './components/Datatables/Datatables'
Vue.component('datatable', Datatable);

Vue.component('tabs', tabs);
Vue.component('tab', tab);


Vue.use(BootstrapVue);
Vue.use(VueAxios, axios)

const swal_options = {
    confirmButtonColor: '#41b882',
    cancelButtonColor: '#ff7674',
    // showConfirmButton: false, 
    // toast: true,
    // timer: 3000,
    // position: 'bottom-end'
};


Vue.use(VueSweetAlert2, swal_options);

Vue.use(VueTouch);
// Vue.use(Trend);

// Vue.use(VueGoogleMaps, {
//   load: {
//     key: 'AIzaSyB7OXmzfQYua_1LEhRdqsoYzyJOPh9hGLg',
//   },
// });
// Vue.component('apexchart', VueApexCharts);
// Vue.use(Toasted, {duration: 10000});
Vue.component('Widget', Widget);
Vue.mixin(layoutMixin);
Vue.config.productionTip = false;

moment.locale('pt');
Vue.prototype.moment = moment;
window.moment = moment;
// window.swal = Vue.swal;


                        // const Toast = Vue.swal.mixin({
                        //     toast: true,
                        //     position: 'top-end',
                        //     showConfirmButton: false,
                        //     timer: 3000,
                        //     timerProgressBar: true,
                        //     didOpen: (toast) => {
                        //         toast.addEventListener('mouseenter', Vue.swal.stopTimer)
                        //         toast.addEventListener('mouseleave', Vue.swal.resumeTimer)
                        //     }
                        // })

                        // Vue.prototype.toast = Toast;

Vue.prototype.swal = Vue.swal;
Vue.prototype.frmLog = function(success = 1, msg) {
    var icon = success ? 'success' : 'error';
    Vue.swal.fire({
        icon: icon,
        title: msg,
        toast: true,
        position: 'bottom-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        showClass:{
            popup: 'm-5 p-5',
            backdrop: 'm-0 p-0'        
        },
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Vue.swal.stopTimer)
            toast.addEventListener('mouseleave', Vue.swal.resumeTimer)
        }
    });
};



Vue.prototype.pushnotification = function(msg) {
    // var icon = success ? 'success' : 'error';
    Vue.swal.fire({
        // icon: icon,
        title: msg,
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 5000,
        timerProgressBar: true,
        showClass:{
            popup: 'm-5 p-5',
            backdrop: 'm-0 p-0'        
        },
        // didOpen: (toast) => {
            // toast.addEventListener('mouseenter', Vue.swal.stopTimer)
            // toast.addEventListener('mouseleave', Vue.swal.resumeTimer)
        // }
    });
};
// Vue.mixin(Toast);

// ENCRIPTACAO
const require_encript  = true;
var Base64 = require('js-base64').Base64;
const ba = Base64.encode , ab = Base64.decode, jsp = JSON.parse, jss = JSON.stringify, ure = encodeURIComponent, urd = decodeURIComponent
const srj = function(a){return a.split('').reverse().join('')}
let jsonxEnc = function(dt){if(dt != '')  return ure(srj(ba(jss(dt))));else return {};}
let jsonxDec = function(dt){if(dt != '')  return jsp(ab(srj(urd(dt))));else return {};}
let dc_data = function(dt) {if(dt != '')  return jsp(ab(dt));else return {};}
window.dc_data = dc_data;

window.jsonxEnc = jsonxEnc;
window.jsonxDec = jsonxDec;

// ### GET USER IP ADDRESS  ###
// axios.get('https://ipinfo.io')
//   .then(function (response) {
//     alert(response.ip); 
//   })
//   .catch(function (error) {
//     // handle error
//     console.log(error);
//   })
//   .then(function () {
//     // always executed
//   });




//Config API -- AXIOS

let configaxios = {
        baseURL: config.app.urlapi,
        port: 80,
        timeout: 30000,
        headers: {
            'Content-Type' : 'multipart/form-data',
            // 'Authorization' : 'Basic '+ba('JWT:'+window.localStorage.getItem('jwt')),
            // 'Udata' : jsonxEnc(window.localStorage.getItem('user'))
        }
  };

  //ENCRYPTED REQUEST
        let api = axios.create(configaxios);

        api.interceptors.request.use(config => {
            // config.headers.post['Authorization'] = 'Basic '+ba('JWT:'+window.localStorage.getItem('jwt'));
            // config.headers.post['Udata'] = window.localStorage.getItem('user');
            config.headers['Uauthorization'] = 'Basic '+ba('JWT:'+window.localStorage.getItem('jwt'));
            config.headers['Udata'] = window.localStorage.getItem('user');
            
            return config;
        });

        api.interceptors.request.use(function (request) {
            if(require_encript) request.data = {data: jsonxEnc(request.data)}
            if(request.url.slice(-1) == '/'){request.url = request.url.slice(0,-1);}
            if(request.url.indexOf(".") === -1) request.url = request.url + (require_encript ? '.jsonx' : '');
            return request;
        }, function (error) {
            return Promise.reject(error);
        });

        api.interceptors.response.use(function (response) {
            if(require_encript) response.data = jsonxDec(response.data);
            if(!response.data.success) console.log(response.data.error);
            return {status: response.data.status, success: response.data.success, error: response.data.error, dados: response.data.dados  };
            //return response.data
        }, function (error) {
            return Promise.reject(error);
        });

        window.api = api;
        Vue.prototype.api = api;
                        
                        //FILE UPLOAD UNENCRYPTED REQUEST 
                        let form = axios.create(configaxios);
                        form.interceptors.request.use(config => {
                            // config.headers.post['Authorization'] = 'Basic '+ba('JWT:'+window.localStorage.getItem('jwt'));
                            // config.headers.post['Udata'] = window.localStorage.getItem('user');
                            config.headers['Uauthorization'] = 'Basic '+ba('JWT:'+window.localStorage.getItem('jwt'));
                            config.headers['Udata'] = window.localStorage.getItem('user');
                            
                            return config;
                        });

                        form.interceptors.request.use(function (request) {
                            if(request.url.indexOf(".") === -1) request.url = request.url + '.json';
                            return request;
                        }, function (error) {
                            return Promise.reject(error);
                        });

                        form.interceptors.response.use(function (response) {
                            //response.data = jsonxDec(response.data);
                            
                            return {status: response.data.status, success: response.data.success, error: response.data.error, dados: response.data.dados  };
                        }, function (error) {
                            return Promise.reject(error);
                        });
                        
                        window.form = form;
                        Vue.prototype.form = form;



//CUSTOM METHODS
        

    Vue.prototype.$isAdmin = () => {
            var user =  window.jsonxDec(window.localStorage.getItem('user'));
            
            if(user.permissions && user.permissions.indexOf('su') !== -1) return true;
            if( user.permissions
                && (user.permissions.indexOf('gag') !== -1
                ||  user.permissions.indexOf('admin') !== -1
                )
            ){    
                return true;
            }else{
                return false;
            }
    }

    Vue.prototype.$isSuperAdmin = () => {
            var user =  window.jsonxDec(window.localStorage.getItem('user'));
            
            if(user.permissions && user.permissions.indexOf('su') !== -1 ){    
                return true;
            }else{
                return false;
            }
    }


/* eslint-disable no-new */
new Vue({
    el: '#app',
    store,
    router,
    render: h => h(App),
});
