<template>
<div class="dashboard-page">

    <b-row style="margin-top: -40px; margin-bottom: 40px;">
        <b-col class="text-right"> 
            
                <button class="btn btn-primary" @click="$router.push({ path: '/marketing/nova_campanha', params: {} })">  Adicionar Campanha </button>
            
        </b-col>
    </b-row>



    <b-row>
        <b-col lg="12" sm="12" xs="12">
            <div class="pb-xlg h-100">
            <Widget class="h-100 mx-0 px-0" xtitle="Capanhas Ativas" xclose>
<!-- 
                <div class="col-12 row mt-3 align-items-center text-center ">
                    <div class="col-1">ID</div>
                    <div class="col-5">Status</div>
                    <div class="col-3">Data</div>
                    <div class="col-3">Canal</div>
                </div>

                <div class="cs_item col-12 row mt-3 mb-4 align-items-center text-center" v-for="(c, k) in campaigns" :key="k" @click="$router.push({ path: '/marketing/'+c.id, params: {} })" style="cursor: pointer;">
                    
                    <div class="col-1">{{c.id}}</div>
                    <div class="col-5">{{c.title}}</div>
                    <div class="col-3">{{c.target}}</div>
                    <div class="col-3">{{c.channel}}</div>
                
                </div>
                 -->

                
                <datatable class=" m-0 p-0" 
                    
                    :selectable="true" 
                    :hastableactions="false" 
                    :searchable="true" 
                    :selectedRows="selected_campaigns"
                    @rowclick="rowclick_callback" 
                    
                    
                    :rows="campaigns"     
                    :columns="[{label:'ID', field:'id'}, {label:'Título Campanha', field:'title'}, {label:'Target', field:'target'}, {label:'Canal', field:'channel'}, {label:'actions', field:'actions', sortable: false }]" 
                    :initialsort = "{field: 'title', type: 'asc'}"
                />

            </Widget>
            </div>
        </b-col>      
    
    </b-row>
</div>
</template>

<script>
import Widget from '@/components/Widget/Widget';

export default {
    name: 'Marketing',
    components: {
        Widget 
    },
    data() {
        // var self = this;

        return {
            account: {},
            selected_campaigns: {},
            campaigns: {}
        };
    },
    computed:{

    },

    created() {
        let self = this;
        self.load_data();    
        console.log(this.table_data);
    },
    watch: {
        query: {
        handler () {
            this.handleQueryChange()
        },
        deep: true
        }
    },
    methods: {

        load_data(){
            let self = this; 

            self.api.post('MKT/Egoi/myAccount').then((r)=>{
                console.log(r);
            });


            this.api.get('MKT/Campanhas/list').then(function(response){
                self.campaigns = response.dados;
                // self.$set(self.table,'data', response.dados);
            });
        },


        delete_callback(value){
            console.log('delete' + value);
        },
        
        rowclick_callback(value){
            //console.log(value);
            let self = this;
            if(value.action == 'link') this.$router.push({ path: '/marketing/'+value.row.id, params: {} });

            if(value.action == 'delete') {
                this.swal.fire({
                    title: 'De certeza que pretende eliminar a campanha '+ value.row.title +'?',
                    showDenyButton: true,
                    showCancelButton: false,
                    confirmButtonText: `Eliminar`,
                    denyButtonText: `Cancelar`,
                    }).then((result) => {
                    if (result.isConfirmed) {
                        self.api.post('MKT/Campanhas/delete',value.row.id).then(function(response){
                            console.log(response);
                            self.api.get('MKT/Campanhas/list').then(function(response){
                                self.campaigns = response.dados;
                                self.swal.fire('Eliminado!', '', 'success')
                                self.$forceUpdate();
                            });

                        });


                    } else if (result.isDenied) {
                        //do Nothing.
                    }
                    })

            }
        },


    }
}
</script>
