<template>
  <div class="sidebar-wrapper">
    <nav
        :class="{sidebar: true, sidebarStatic, sidebarOpened}"
        @mouseenter="sidebarMouseEnter"
        @mouseleave="sidebarMouseLeave"
    >
    <div class="d-flex flex-column" style="height: 100%;">
      <header class="logo">
        <router-link to="/dashboard">

          <span class="primary-word">
            <img src="../../assets/imagens/tg-icon.png" alt="Travel Gate" style="height: 45px;">
          </span> <img src="../../assets/imagens/tg-logo-inv.png" alt="Travel Gate" style="height: 35px;">

          <!-- <span class="primary-word" v-if="!sidebarOpened && !sidebarStatic">
            <img :src="appConfig.options.base_url+appConfig.options.brand_icon" alt="" style="height: 45px;"  v-if="appConfig.options && appConfig.options.brand_icon">
            <img src="../../assets/imagens/tg-logo-inv.png" alt="" style="height: 45px;" v-else="">
          </span> 
          <span class="primary-word" v-else="">
            <img :src="appConfig.options.base_url+appConfig.options.brand_logo" alt="" style="height: 45px;"  v-if="appConfig.options && appConfig.options.brand_logo">
            <img src="../../assets/imagens/tg-logo-inv.png" alt="" style="height: 45px;" v-else="">
          </span>  -->

        </router-link>
      </header>
      <ul class="nav">
        <NavLink
            :activeItem="activeItem"
            header="Dashboard"
            link="/dashboard"
            iconName="flaticon-home"
            index="dashboard"
            isHeader
        />


        <NavLink
            :activeItem="activeItem"
            header="Atendimento"
            link="/atendimento"
            iconName="flaticon-calendar"
            index="atendimento"
            isHeader
        />

        <NavLink
            :activeItem="activeItem"
            header="Email"
            link="/mailbox"
            iconName="flaticon-paper-plane"
            index="mailbox"
            isHeader
        />


        <NavLink
            :activeItem="activeItem"
            header="Clientes"
            link="/clients"
            iconName="flaticon-users"
            index="clients"
            isHeader
            :childrenLinks="[
              { header: 'Accounts', link: '/accounts' },
              { header: 'Clientes', link: '/clientes' },
              { header: 'Leads', link: '/leads' }
            ]"

        />
        
        <NavLink
            :activeItem="activeItem"
            header="Campanhas"
            link="/marketing"
            iconName="flaticon-network"
            index="marketing"
            isHeader
        />


        <NavLink
            :activeItem="activeItem"
            header="Suporte"
            link="/suporte"
            iconName="flaticon-settings-4"
            index="suporte"
            :childrenLinks="[
              { header: 'Pedido de Reembolso a Fornecedor', link: '/suporte/reembolso' },
              { header: 'Pedido de Reembolso a Cliente', link: '/suporte/reembolso-cliente' },
              { header: 'Pedido de Nota de Crédito', link: '/suporte/nota-de-credito' },
              { header: 'Gestão de ADMs', link: '/suporte/adms' }              
            ]"
        />


        <!-- <NavLink
            :activeItem="activeItem"
            header="Pedido Viagem"
            link="/pedido-viagem"
            iconName="flaticon-paper-plane"
            index="pedido-viagem"
            isHeader
        /> -->
        <!-- <NavLink
            :activeItem="activeItem"
            header="Typography"
            link="/typography"
            iconName="flaticon-list"
            index="typography"
            isHeader
        /> -->
        <!-- <NavLink
            :activeItem="activeItem"
            header="Tables Basic"
            link="/tables"
            iconName="flaticon-equal-1"
            index="tables"
            isHeader
        /> -->
        <!-- <NavLink
            :activeItem="activeItem"
            header="Notifications"
            link="/notifications"
            iconName="flaticon-star"
            index="notifications"
            isHeader
        /> -->
        <!-- <NavLink
            :activeItem="activeItem"
            header="Template"
            link="/components"
            iconName="flaticon-network"
            index="components"
            :childrenLinks="[
              { header: 'Dashboard Original', link: '/dashboard-original' },
              { header: 'Notifications', link: '/notifications' },
              { header: 'Tables', link: '/tables' },
              { header: 'Typography', link: '/typography' },
              { header: 'Charts', link: '/components/charts' },
              { header: 'Icons', link: '/components/icons' },
              { header: 'Maps', link: '/components/maps' },
            ]"
        /> -->
      </ul>

<!-- 
      
      <h5 class="navTitle">
        Ferramentas
        <a class="actionLink">
          <i class="la la-plus float-right"/>
        </a>
      </h5>
      <ul class="sidebarLabels">
        <li>
          <a href="#">
            <i class="fa fa-circle xtext-danger mr-2"/>
            <span class="labelName">Knowledge Base</span>
          </a>
        </li>
        <li>
          <a href="#">
            <i class="fa fa-circle xtext-danger mr-2"/>
            <span class="labelName">Documentos Internos</span>
          </a>
        </li>
        <li>
          <a href="#">
            <i class="fa fa-circle xtext-danger mr-2"/>
            <span class="labelName">Gestão de Senhas</span>
          </a>
        </li>
      </ul> -->



      <div id="bottom-menu" class="mt-auto">
      
      <ul class="nav">

        <NavLink
          :activeItem="activeItem"
          header="User"
          link="/user"
          iconName="flaticon-user"
          index="user"
          isHeader
          :childrenLinks="[
            { header: 'Meu Perfil', link: '/user' },
          ]"
      />

        <NavLink
            :activeItem="activeItem"
            header="Ferramentas"
            link="/ferramentas"
            iconName="flaticon-settings-3"
            index="ferramentas"
            isHeader
            :childrenLinks="[
              { header: 'Knowledge Base', link: '#1' },
              { header: 'Documentos Internos', link: '#2' },
              { header: 'Senhas e Acessos', link: '#3' }
            ]"
        />


      
      
        <NavLink
            :activeItem="activeItem"
            header="Config"
            link="/config"
            iconName="flaticon-app"
            index="config"
            
            :childrenLinks="[
              { header: 'Configurar Sistema', link: '/config' },

              { header: 'Dashboard Original', link: '/dashboard-original' },
              { header: 'Notifications', link: '/notifications' },
              { header: 'Tables', link: '/tables' },
              { header: 'Typography', link: '/typography' },
              { header: 'Charts', link: '/components/charts' },
              { header: 'Icons', link: '/components/icons' },
              { header: 'Maps', link: '/components/maps' },
            ]"
        />


      </ul>
      </div>

    </div>


      <!-- <h5 class="navTitle">
        LABELS
        <a class="actionLink">
          <i class="la la-plus float-right"/>
        </a>
      </h5>
      <ul class="sidebarLabels">
        <li>
          <a href="#">
            <i class="fa fa-circle text-danger mr-2"/>
            <span class="labelName">My Recent</span>
          </a>
        </li>
        <li>
          <a href="#">
            <i class="fa fa-circle text-primary mr-2"/>
            <span class="labelName">Starred</span>
          </a>
        </li>
        <li>
          <a href="#">
            <i class="fa fa-circle mr-2"/>
            <span class="labelName">Background</span>
          </a>
        </li>
      </ul> -->
      <!-- <h5 class="navTitle">
        PROJECTS
      </h5>
      <div class="sidebarAlerts">
        <b-alert
            v-for="alert in alerts"
            :key="alert.id"
            class="sidebarAlert" variant="transparent"
            show dismissible
        >
          <span>{{alert.title}}</span><br/>
          <b-progress class="sidebarProgress progress-xs mt-1"
                      :variant="alert.color" :value="alert.value" :max="100"/>
          <small>{{alert.footer}}</small>
        </b-alert>
      </div> -->
    </nav>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import isScreen from '@/core/screenHelper';
import NavLink from './NavLink/NavLink';

export default {
  name: 'Sidebar',
  components: { NavLink },
  data() {
    return {
      alerts: [
        {
          id: 0,
          title: 'Sales Report',
          value: 15,
          footer: 'Calculating x-axis bias... 65%',
          color: 'danger',
        },
        {
          id: 1,
          title: 'Personal Responsibility',
          value: 20,
          footer: 'Provide required notes',
          color: 'primary',
        },
      ],
    };
  },
  methods: {
    ...mapActions('layout', ['changeSidebarActive', 'switchSidebar']),
    setActiveByRoute() {
      const paths = this.$route.fullPath.split('/');
      paths.pop();
      this.changeSidebarActive(paths.join('/'));
    },
    sidebarMouseEnter() {
      if (!this.sidebarStatic && (isScreen('lg') || isScreen('xl'))) {
        this.switchSidebar(false);
        this.setActiveByRoute();
      }
    },
    sidebarMouseLeave() {
      if (!this.sidebarStatic && (isScreen('lg') || isScreen('xl'))) {
        this.switchSidebar(true);
        this.changeSidebarActive(null);
      }
    },
  },
  created() {
    this.setActiveByRoute();
  },
  computed: {
    ...mapState('layout', {
      sidebarStatic: state => state.sidebarStatic,
      sidebarOpened: state => !state.sidebarClose,
      activeItem: state => state.sidebarActiveElement || 'dashboard',
    }),
  },
};
</script>

<!-- Sidebar styles should be scoped -->
<style src="./Sidebar.scss" lang="scss" scoped/>
