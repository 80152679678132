<template>
    <div class="new-campaign-page">
    

        <b-row>
            <b-col xs="12">
                <div class="pb-xlg h-100">
                <Widget class="h-100 mb-0" xtitle="Capanhas Ativas" xclose>

                    <form class="col-12" @submit.prevent="save_campaign">
                        
                        <div class="formgroup col-12 row mt-2">
                            <label class="col-12 col-md-4" for="title_campanha">Campanha</label>
                            <input class="col-12 col-md-8" type="text" id="title_campanha" v-model="campaign.title" required>
                        </div>


                        <div class="formgroup col-12 row mt-2">
                            <label class="col-12 col-md-4" for="canal_campanha">Canal</label>
                            <select class="col-12 col-md-8" v-model="campaign.channel" id="canal_campanha" required>
                                <option value="sms" selected>SMS</option>
                            </select>
                        </div>


                        <div class="formgroup col-12 row mt-2">
                            <label class="col-12 col-md-4" for="target_campanha">Target</label>
                            <select class="col-12 col-md-8" v-model="campaign.target" id="target_campanha" required>
                                <option value="leads" >Leads</option>
                                <option value="clients" >Clientes Ativos</option>
                            </select>
                        </div>

                        <div class="formgroup col-12 row mt-2">
                            <label class="col-12 col-md-4" for="msg_campanha">Mensagem</label>
                            <div class="col-12 col-md-8 p-0 m-0">
                                <textarea class="col-12" type="text" id="msg_campanha" v-model="campaign.message" required> </textarea>
                                <span> {{campaign.message ? campaign.message.length : 0}} / 160 </span>
                            </div>
                        </div>


                        <div class="col-12 mt-5">
                            <b-row>
                                <b-col xs="12">
                                    <div class="col-12 row d-flex justify-content-center text-center">
                                        <button class="btn btn-primary col-12 py-3" type="submit" v-if="!saving">Guardar</button>
                                        <span class="btn btn-primary col-12 py-3" v-if="saving" disabled><i class="fa fa-spin fa-spinner"></i> A guardar dados </span>
                                    </div>
                                    <!-- <pre>{{cs}}</pre> -->                                    
                                </b-col>
                            </b-row>
                        </div>

                    </form>

                </Widget>
                </div>
            </b-col>          
        </b-row>
    </div>
</template>

<script>
import Widget from '@/components/Widget/Widget';

export default {
name: 'NovaCampanha',
components: {
    Widget 
},
data() {
    return {
        error_log: {}, 
        saving: false,
        campaign: {}
    };
},
computed:{
    
},
created() {

},
methods: {
    save_campaign(){
            let self = this;

            self.saving = true;

            this.api.post('MKT/Campanhas/save',this.campaign).then(function(response){

                console.log(response);
                self.$router.push({ path: '/marketing', params: {} });
                self.saving = false;
            });


            
    }
},

};
</script>
