<template>
    <div>


            <div class="formgroup col-12 mt-4 row">
                <label class="col-12 col-md-4 xtext-right" for="req_file">File</label>
                <input class="col-12 col-md-8" type="text" id="req_file" v-model="at.file">
            </div>
            <div class="formgroup col-12 mt-4 row">
                <label class="col-12 col-md-4 xtext-right" for="req_subject">Assunto</label>
                <input class="col-12 col-md-8" type="text" id="req_subject" v-model="at.subject" required>
                
            </div>
            <div class="formgroup col-12 row panel">
                <!-- <div class="col-12 panel-header px-2 py-3 mb-5">
                    Observações
                </div> -->
                <label class="col-12 col-md-4 xtext-right" for="frm_obs"></label> 
                <textarea class="col-12 col-md-8" rows="10" type="text" id="frm_obs" v-model="at.details" required></textarea>
            </div>


    </div>
</template>

<script>

export default {
name: 'PostSale',
props: ['at'],
components: {
},
data() {
    return {
        //declaração de variáveis locais
        
    };
},
created() {
    
},
mounted() {
    // 
},
watch: {
    
},
filters: {
    
},
methods: {

},
computed: {

}
};
</script>

<style scoped>
    .formgroup{ margin-bottom: 25px; }
    p{ color: #900; }
</style>