<template>
  <section class="notifications navbar-notifications">
    <header class="cardHeader card-header">
      <div class="text-center mb-sm">
        <strong>Tem {{CountNewNotifications}} notificações</strong>
      </div>
      <!-- 
      <b-button-group id="notification-b-buttons">
        <b-button variant="default" @click="changeNotificationsTab(1)">Notifications</b-button>
        <b-button variant="default" @click="changeNotificationsTab(2)">Messages</b-button>
        <b-button variant="default" @click="changeNotificationsTab(3)">Progress</b-button>
      </b-button-group> 
      -->
    </header>
      <!-- <NewNotifictionsList v-if="newNotifications" /> -->
      <!-- <NotifictionsList v-else-if="notificationsTabSelected === 1" />
      <Messages v-else-if="notificationsTabSelected === 2" />
      <Progress v-else-if="notificationsTabSelected === 3" /> -->
    <NotificationsList :notificationList='newNotifications' @click="clickCallback"/>
    <footer class="cardFooter text-sm card-footer">
      <span class="fs-mini">Synced at: 21 Apr 2014 18:36</span>
      <b-button
        variant="link"
        @click="loadNotifications"
        :class="{disabled: isLoad, 'btnNotificationsReload btn-xs float-right py-0': true}"
      >
        <span v-if="isLoad"><i class="la la-refresh la-spin" /> Loading...</span>
        <i v-else class="la la-refresh" />
        
      </b-button>
    </footer>
  </section>
</template>

<script>
import Vue from 'vue';

import NotificationsList from './Notifications/NotificationsList';
// import NewNotifictionsList from './NotificationsDemo/NewNotificationsList';
// import Messages from './NotificationsDemo/Messages';
// import Progress from './NotificationsDemo/Progress';

export default {
  name: 'Notification',
  props: ['visible'],
  components: {
    // NotifictionsList, NewNotifictionsList, Messages, Progress,
    NotificationsList
  },
  data() {
    return {
      notificationsTabSelected: 1,
      newNotifications: [],
      isLoad: false,
    };
  },
  mounted(){
    // console.log('notificações ativas');
    this.loadNotifications();
    window.setInterval(() => {
      this.loadNotifications();
    }, 30000)
  },
  computed: {

    CountNewNotifications(){
      var novos = this.newNotifications.filter((n) => {return n.is_seen == 0 });
      return novos.length;
      // return this.newNotifications.length;
    }

  },
  watch: {
        'visible': function(val){
          if(val){
            // this.loadNotifications();
          }else{
            this.markAsSeen();
          }
        },
        'CountNewNotifications': function(){
            this.$emit("updateBadge", this.CountNewNotifications);
        },
        'newNotifications': {
          handler(newValue, oldValue) { 
            // console.log(oldValue);
            // console.log(newValue);
            if(oldValue.length && oldValue !== newValue){
              newValue.forEach(element => {
                if(!element.is_seen){
                  this.swal('Hello Vue world!!!');
                  this.pushnotification(element.notification);
                }
              });
            }
          },
          deep: true
        }
  },
  methods: {
    clickCallback(ev){
      this.markAsRead(ev.id);
      this.$router.push(ev.link);
    },
    changeNotificationsTab(tab) {
      Vue.set(this, 'notificationsTabSelected', tab);
      // Vue.set(this, 'newNotifications', null);
    },
    loadNotifications() {
      let self = this;
      self.$set(self, 'isLoad', true);
      this.api.post('Sys/Notifications/list/').then(function(response){
        // console.log(response);
        self.newNotifications = response.dados;
        self.$set(self, 'isLoad', false);
      });
      // setTimeout(() => {
      //   Vue.set(this, 'newNotifications', 'new notifications component');
      //   Vue.set(this, 'isLoad', false);
      // }, 1500);
    },
    markAsSeen(){
      this.newNotifications.forEach((element) => {
              element.is_seen = true;
      });
      this.api.post('Sys/Notifications/markAsSeen/').then(function(){});
    },
    markAsRead(id){
      this.api.post('Sys/Notifications/markAsRead/', {notification_id : id}).then(function(){});
    }
  },
};
</script>

<style src="./Notifications.scss" lang="scss" />