<template>
    <div class="col-12 row m-0 p-0">
        <!-- Form {{id ? 'editar ' + id : 'novo'}} -->
        <div class="d-flex align-items-center justify-content-center col-12 row" v-if="loading" style="height: 80vh; width:100%;">
            <div class="col-12 text-center">
                <i class="fa fa-spin fa-spinner fa-4x"></i>
            </div>
        </div>

        <form class="col-12 row m-0 p-0 pt-5" @submit.prevent="save" v-if="!loading">

            <!-- <pre class="col-12">{{data}}</pre> -->
        <Widget>
            <div class="col-12 text-center"  v-if="!options.loaded">
                <i class="fa fa-5x my-5 py-5 fa-spin fa-spinner"></i>
            </div>

            <div :class="data.id ? 'col-12' : 'col-12'" v-if="options.loaded">
                
                        <div class="formgroup col-12 row" >
                            <div class="formgroup col-12 row ">
                                <label class="col-4" for="">ID</label>
                                <div class="col-8 row m-0 p-0">
                                    <input type="text" class="col-12" v-model="id" >
                                    <p class="error text-danger" v-if="frmerror.id"> Campo obrigatório</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-12 my-3 pt-5 ">
                            <div class="formgroup col-12 text-center">
                                <button class="btn btn-save border px-5" type="submit" v-if="!saving">Guardar</button>
                                <span class="btn btn-save px-5 border" v-if="saving"><i class="fa fa-spin fa-spinner"></i></span>
                            </div>
                        </div>

                    
                        <!--  
                    <div class="formgroup col-12 row">
                        {{data}}
                    </div> 
                    -->
            </div>

            <!-- 
            <div class="col-4" v-if="data.course_id">
                <Widget>

                </Widget>
            </div> 
            -->
        
        </Widget>
        </form>
    </div>
</template>
    
<script>
    // import Search from '../partials/search';

    export default {
    name: 'Form',
    // components: {Search},
    props: ['options'],
    data() {
        return {
            frmerror: {},
            saving: false,
            loading: false,
            
            data: {}
        };
    },
    computed: {
        id(){
            let id = this.$route.params.id || null;
            return id;
            // return Number.isInteger(id) ? id : null
        }
    },
    watch:{
        id(){
            let self = this;
            self.$set(this, 'loading', true);
            // alert('id = ' + val);
            setTimeout(function (){
                self.$set(self, 'loading', false);
            }, 5000); 
        },
    },
    methods: {
        save(){
            let self = this;
            self.saving = true;
            console.log(self.data);
            setTimeout(function (){
                self.saving = false;
                self.frmLog(true, 'Dados Guardados com sucesso')
            }, 2500); 

            // self.api.post('tad/courses/save_action', self.data).then( (r)=>{
            //     if(r.success){
            //         self.saving = false;
            //         this.$router.push('/formacao/cursos-agendados/'+r.dados);
            //     }else{
            //         self.frmerror = r.error;
            //     }
            // });
            
        }
    },
    beforeMount() {
        
    },
    mounted() {   
        let self = this;
        if(this.$route.params.id) {
            // self.api.get('tad/courses/get_action/'+this.id).then((r)=>{
            //     console.log(r);
            //     self.$set(self, 'data', r.dados);
            //     if(self.data.course) self.data.course = {};
            // });
        }
    },
    created() {
        
    }
}
</script>
    