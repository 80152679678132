<template>
  <b-navbar class="header d-print-none app-header">
    <b-nav>
      <b-nav-item>
        <a class="d-md-down-none px-2" href="#" @click="toggleSidebarMethod" id="barsTooltip">
          <i class='la la-bars la-lg' />
        </a>
        <a class="fs-lg d-lg-none" href="#" @click="switchSidebarMethod">
          <i class="la la-bars la-lg" />
        </a>
      </b-nav-item>
      <!-- <b-nav-item class="d-md-down-none">
        <a href="#" class="px-2">
          <i class="la la-refresh la-lg" />
        </a>
      </b-nav-item>
      -->
      <b-nav-item class="d-md-down-none">
        <a @click="$router.push({ path: '/atendimento/novo', params: {} })" class="px-2">
          <i class="la la-plus la-lg" /> Registar novo atendimento
        </a>
      </b-nav-item>
    </b-nav>
    <b-nav>
      <!-- <b-form class="d-sm-down-none ml-5" inline>
        <b-form-group>
          <b-input-group class="input-group-no-border">
            <template v-slot:prepend>
              <b-input-group-text><i class="la la-search"/></b-input-group-text>
            </template>
            <b-form-input id="search-input" placeholder="Search Dashboard" />
          </b-input-group>
        </b-form-group>
      </b-form> -->
    </b-nav>
    <a  class="navbarBrand d-md-none">
      <!-- <i class="fa fa-circle text-primary mr-n-sm" />
      <i class="fa fa-circle text-danger" /> -->
      &nbsp;
      <!-- <img src="../../assets/imagens/tg-logo.png" alt="Travel Gate" style="height: 15px;"> -->
      &nbsp;
      <!-- <i class="fa fa-circle text-danger mr-n-sm" />
      <i class="fa fa-circle text-primary" /> -->
    </a>
    <b-nav class="ml-auto">


      <b-nav-item-dropdown
        class="notificationsMenu d-md-down-none mr-2"
        menu-class="notificationsWrapper py-0 animated animated-fast fadeIn"
        no-caret 
        right
        @show="notificationsTabOpen = true"
        @hide="notificationsTabOpen = false"
        >
        <template slot="button-content">
          <!-- <span class="avatar rounded-circle thumb-sm float-left mr-2">
            <img class="rounded-circle" :src="user.profile_picture" xsrc="../../assets/people/a5.jpg" alt="..." />
          </span> -->
          <!-- <span>{{ (notificationsTabOpen) ? 'aberto' : 'fechado' }}</span> -->
          <span class="small mr-2">Olá, {{user.name}}</span>
          <span class="ml-1 circle bg-primary text-white fw-bold">{{newNotifications}}</span>
        </template>
        <Notifications :visible="notificationsTabOpen" @updateBadge='updateNotificationBadge' />
      </b-nav-item-dropdown>


      <b-nav-item-dropdown id="v-step-2" class="settingsDropdown d-sm-down-none" no-caret right>
        <template slot="button-content">
          <i class="la la-cog px-2" />
        </template>
        <b-dropdown-item><i class="la la-user" /> Meu perfil</b-dropdown-item>
        <!-- <b-dropdown-divider />
        <b-dropdown-item>Calendar</b-dropdown-item>
        <b-dropdown-item>
          Inbox &nbsp;&nbsp;<b-badge variant="danger" pill class="animated bounceIn">9</b-badge>
        </b-dropdown-item> -->
        <b-dropdown-divider />
        <b-dropdown-item-button @click="logout">
          <i class="la la-sign-out" /> Log Out
        </b-dropdown-item-button>
      </b-nav-item-dropdown>


    </b-nav>
  </b-navbar>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Notifications from '@/components/Notifications/Notifications';

export default {
  name: 'Header',
  components: { Notifications },
  data() {
    return {
      notificationsTabOpen : false,
      newNotifications: 0,
      user : {}
    }
  },
  computed: {
    ...mapState('layout', ['sidebarClose', 'sidebarStatic']),
  },
  mounted() {
    this.user = window.jsonxDec(window.localStorage.getItem('user'));
  },
  methods: {
    seeNotifications(){
      // alert('see notifications');
    },
    updateNotificationBadge(event){
      // console.log(event);
      this.newNotifications = event;

    },
    ...mapActions('layout', ['toggleSidebar', 'switchSidebar', 'changeSidebarActive']),
    switchSidebarMethod() {
      if (!this.sidebarClose) {
        this.switchSidebar(true);
        this.changeSidebarActive(null);
      } else {
        this.switchSidebar(false);
        const paths = this.$route.fullPath.split('/');
        paths.pop();
        this.changeSidebarActive(paths.join('/'));
      }
    },
    toggleSidebarMethod() {
      if (this.sidebarStatic) {
        this.toggleSidebar();
        this.changeSidebarActive(null);
      } else {
        this.toggleSidebar();
        const paths = this.$route.fullPath.split('/');
        paths.pop();
        this.changeSidebarActive(paths.join('/'));
      }
    },
    logout() {
      window.localStorage.setItem('authenticated', false);
      window.localStorage.removeItem('jwt', false);
      window.localStorage.removeItem('user', false);
      
          this.$store.state.user.authenticated = false;
          this.$store.state.user.permissions = [];

      this.$router.push('/login');
    },
  }
};
</script>

<style src="./Header.scss" lang="scss"></style>