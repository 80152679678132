<template>
    <div>
    </div>
</template>

<script>
export default {
name: 'Envios',
props: [],
components: {
},
data() {
},
created() {
},
watch: {
},
filters: {
},
methods: {
},
computed: {
}
};
</script>

<style scoped>

</style>